import { GetterTree } from 'vuex'
import { AlarmUiGetter, AlarmUiState } from './types'
import { RootState } from '@/store/types'
import { AlarmType, AlarmViewModel } from './models'
import { AppIdMobileApp } from '@ecocoach/domain-store-modules/src/alarm/types'
import moment from 'moment'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { AlarmLevel, PlcOperationState } from '@ecocoach/domain-store-modules/src/common'

export const getters: GetterTree<AlarmUiState, RootState> = {
  [AlarmUiGetter.alarms](_, __, rootState, rootGetters): AlarmViewModel[] {
    const stringResource = rootGetters[`resource/${ResourceGetter.dictionary}`]
    const formattedTimeStamp = (timeStamp: string | null) => {
      return timeStamp ? moment.utc(timeStamp).local().format('DD.MM.YYYY - HH:mm:ss') : ''
    }
    const serviceAlarms: AlarmViewModel[] = rootState.alarm.serviceAlarms
      .filter(alarm => alarm.active && alarm.appIds.includes(AppIdMobileApp))
      .map(alarm => {
        return {
          id: alarm.alarmId,
          title: stringResource('alarm.service.solving'),
          icon: 'error',
          text: `${stringResource('alarm.timestamp')}: ${formattedTimeStamp(alarm.timeStamp)} ${stringResource('alarm.clock')}`,
          type: AlarmType.ServiceAlarm,
        }
      })
    const messageAlarms: AlarmViewModel[] = rootState.alarm.messageAlarms
      .filter(alarm => alarm.active && alarm.appIds.includes(AppIdMobileApp))
      .map(alarm => {
        return {
          id: alarm.alarmId,
          title: alarm.message,
          icon: 'build',
          text: `${stringResource('alarm.timestamp')}: ${formattedTimeStamp(alarm.timeStamp)} ${stringResource('alarm.clock')}`,
          type: AlarmType.MessageAlarm,
        }
      })
    const plcIds = rootState.plcOperation.plcs.map(plc => plc.id)
    const customPlcAlarms: AlarmViewModel[] = rootState.alarm.customPlcAlarms
      .filter(alarm => alarm.isOpen && alarm.level === AlarmLevel.Critical && plcIds.includes(alarm.plcId))
      .map(alarm => {
        const displayName = alarm.alarmDefinitionName || stringResource(alarm.nameResourceId)
        const displayMessage = alarm.alarmDefinitionMessage || stringResource(alarm.messageResourceId)
        return {
          id: alarm.alarmInstanceId,
          title: displayName,
          icon: 'error',
          text: `${displayMessage} ${stringResource('alarm.timestamp')}: ${formattedTimeStamp(alarm.openedTimestamp)} ${stringResource('alarm.clock')}`,
          type: AlarmType.CustomPlcAlarm,
          level: alarm.level,
        }
      })
    const offlinePlcAlarms: AlarmViewModel[] = rootState.plcOperation.plcs
      .filter(plc => plc.plcOperationState === PlcOperationState.Disconnected || plc.plcOperationState === PlcOperationState.Offline)
      .map(plc => {
        return {
          id: plc.id,
          title: stringResource('alarm.plc.offline'),
          icon: 'wifi_off',
          text: `${plc.name} ${stringResource('alarm.plc.offline.since')}: ${formattedTimeStamp(plc.plcOperationStateTimeStamp)} ${stringResource('alarm.clock')}`,
          type: AlarmType.OfflinePlcAlarm,
        }
      })
    return [...serviceAlarms, ...messageAlarms, ...offlinePlcAlarms, ...customPlcAlarms]
  },
  [AlarmUiGetter.isDrawerVisible]({ drawerVisible }) {
    return drawerVisible
  },
  [AlarmUiGetter.isServiceDisturbed](_, localGetters) {
    const disturbingAlarmTypes = [ AlarmType.ServiceAlarm, AlarmType.MessageAlarm, AlarmType.OfflinePlcAlarm]
    return (localGetters[AlarmUiGetter.alarms] as AlarmViewModel[]).some(a => disturbingAlarmTypes.includes(a.type))
  },
}
