<template>
  <app-view>
    <icon-header
      :title="'navigation.account' | translate"
      icon="eco.account.white"
    />
    <list-navigation-item
      label="settings.account.name"
      :value="userFirstName"
      :readonly="true"
    />
    <list-navigation-item
      label="settings.account.surname"
      :value="userLastName"
      :readonly="true"
    />
    <list-navigation-item
      label="settings.account.mail"
      :value="userEmail"
      :readonly="true"
    />
    <list-navigation-item
      label="settings.account.language"
      :value="userLanguage"
      @selected="navigateTo('language')"
    />
    <list-navigation-item
      label="settings.account.mfa"
      :value="isEmailMfaEnabled ? 'settings.account.mfa.email' : 'settings.account.mfa.none' | translate"
      @selected="navigateTo('mfa')"
    />
    <list-navigation-item
      label="settings.account.delete"
      :value="'settings.account.delete.info' | translate"
      @selected="showDeleteAccountConfirmationDialog = true"
    />
    <list-navigation-item
      label="settings.account.edit"
      :value="'settings.account.edit.info' | translate"
      @selected="onEditProfile"
    />
    <list-navigation-item
      icon="eco.logout.white"
      label="settings.account.logout"
      @selected="onLogout"
    />
    <confirm-dialog
      :show="showDeleteAccountConfirmationDialog"
      :header="'settings.account.delete' | translate"
      :text="'settings.account.delete.confirm' | translate"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      :disabled="isInteracted"
      @confirmed="onInitiateAccountDeletion"
      @cancelled="showDeleteAccountConfirmationDialog = false"
    />
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import ListNavigationItem from './shared/ListNavigationItem.vue'
import StackRouter from '../router'
import { UserDetails } from '../../../eco-domain-store-modules/src/authentication/models'

const Authentication = namespace('authentication')
const App = namespace('app')

@Component({
  components: {
    AppView,
    IconHeader,
    ListNavigationItem,
  },
})
export default class Account extends Vue {
  @App.Getter public isInteracted: boolean
  @App.Getter public languages: Array<{ id: string, label: string }>
  @App.Getter public selectedLanguage: string
  @App.Getter public isEmailMfaEnabled: boolean | null
  @App.Action public initiateAccountDeletion: () => Promise<void>
  @Authentication.Getter public loggedInUser: UserDetails
  @Authentication.Action public logout: () => Promise<void>
  @Authentication.Action public editProfile: () => Promise<void>

  public showDeleteAccountConfirmationDialog = false

  public get userFirstName(): string {
    return this.loggedInUser.firstName
  }

  public get userLastName(): string {
    return this.loggedInUser.lastName
  }

  public get userEmail(): string {
    return this.loggedInUser.userIdentifier
  }

  public get userLanguage(): string {
    const language = this.languages.find(l => l.id === this.selectedLanguage)
    return language && language.label || ''
  }

  public navigateTo(routeName: string) {
    Vue.$log.info('Navigating to', routeName)
    StackRouter.navigate({ name: routeName })
  }

  public async onInitiateAccountDeletion() {
    await this.initiateAccountDeletion()
    this.showDeleteAccountConfirmationDialog = false
    await this.logout()
  }

  public async onEditProfile() {
    Vue.$log.info('editProfile')
    await this.editProfile()
  }

  public async onLogout() {
    Vue.$log.info('logout')
    await this.logout()
  }
}
</script>
<style lang="scss" scoped>
.account {
  padding: 20px;
}
.select {
  padding: 0;
}
.field {
  padding-bottom: 20px;
}
</style>