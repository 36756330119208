import { TabModel, ToastModel, AppVersionInfo } from './models'

export interface AppState {
  ready: boolean
  interacted: boolean
  tabs: TabModel[]
  selectedProjectId: string
  selectedPlcId: string
  isLoading: boolean
  selectedLanguage: string
  includeDemoProjects: boolean | null
  toast: ToastModel | null
  versionInfo: AppVersionInfo | null
}

export enum AppAction {
  load = 'load',
  pause = 'pause',
  resume = 'resume',
  loadVersionInfo = 'loadVersionInfo',
  loadApiData = 'loadApiData',
  connectHubs = 'connectHubs',
  disconnectHubs = 'disconnectHubs',
  invokeHubs = 'invokeHubs',
  selectProject = 'selectProject',
  acceptUserAgreement = 'acceptUserAgreement',
  selectLanguage = 'selectLanguage',
  setIncludeDemoProjects = 'setIncludeDemoProjects',
  initiateAccountDeletion = 'initiateAccountDeletion',
  setEmailMfaEnabled = 'setEmailMfaEnabled',
  toastInfo = 'toastInfo',
  toastWarning = 'toastWarning',
  toastError = 'toastError',
  hideToast = 'hideToast',
  handleApiError = 'handleApiError',
  handleHubReconnecting = 'handleHubReconnecting',
  handleHubReconnected = 'handleHubReconnected',
  handlePushNotificationOpened = 'handlePushNotificationOpened',
}

export enum AppMutation {
  setInteracted = 'setInteracted',
  setReady = 'setReady',
  selectProject = 'selectProject',
  setSelectedPlcId = 'setSelectedPlcId',
  setLoading = 'setLoading',
  selectLanguage = 'selectLanguage',
  setIncludeDemoProjects = 'setIncludeDemoProjects',
  setToast = 'setToast',
  setVersionInfo = 'setVersionInfo',
}

export enum AppGetter {
  showNavigationForRoute = 'showNavigationForRoute',
  showPlcNameInHeaderForRoute = 'showPlcNameInHeaderForRoute',
  updateAvailable = 'updateAvailable',
  updateRequired = 'updateRequired',
  isLoading = 'isLoading',
  selectedProjectId = 'selectedProjectId',
  selectedProject = 'selectedProject',
  selectedPlcId = 'selectedPlcId',
  selectedPlc = 'selectedPlc',
  userAgreements = 'userAgreements',
  languages = 'languages',
  selectedLanguage = 'selectedLanguage',
  includeDemoProjects = 'includeDemoProjects',
  isEmailMfaEnabled = 'isEmailMfaEnabled',
  tabs = 'tabs',
  isInteracted = 'isInteracted',
  version = 'version',
  buildNumber = 'buildNumber',
  isEcocoachEmployee = 'isEcocoachEmployee',
  userIdentifier = 'userIdentifier',
  isMobile = 'isMobile',
  toast = 'toast',
  showEnergyNextTab = 'showEnergyNextTab',
  projects = 'projects',
  plcs = 'plcs',
  hasDemoProjects = 'hasDemoProjects',
}
