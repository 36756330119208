<template>
  <v-layout column align-center justify-center fill-height class="app-loading">
    <div class="label">{{ label }}</div>
    <base-spinner />
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'

const App = namespace('app')

@Component({
  components: {
    AppView,
  },
})
export default class Loading extends Vue {
  @App.Getter public selectedLanguage: string
  public get label() {
    switch (this.selectedLanguage) {
    case 'de': return 'Daten werden geladen'
    case 'it': return 'Caricamento dei dati in corso'
    default: return 'Loading data'
    }
  }
}
</script>

<style lang="scss" scoped>
.app-loading {
  flex: 1;
  .label {
    flex: 0;
    font-size: 1.5em;
  }
  .base-spinner {
    flex: 0;
  }
}

</style>
