import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { ChartUiState, ChartUiAction, ChartUiMutation, ChartUiGetter } from './types'
import { getNextPeriodStartUtc, getPeriodStartUtc, getPreviousPeriodStartUtc, makeChartConfig } from './helpers'
import { ChartDataInput, ChartReportInput, PeriodType } from '@ecocoach/domain-store-modules/src/chart/models'
import Highcharts from 'highcharts'
import { ChartAction } from '@ecocoach/domain-store-modules/src/chart/types'
import { StatusViewChartCollection } from './models'
import AppDataStorageService from '@ecocoach/domain-store-modules/src/services/appdatastorage.service'
import moment from 'moment'
import { ChartCollectionIdStorageKey, ChartIdStorageKey, ChartNodeIdStorageKey, ChartScalingStorageKey } from '../app/models'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'

const TIMER_INTERVAL = 10000 // 10s
let timerHandle: any | null = null

export const actions: ActionTree<ChartUiState, RootState> = {
  async [ChartUiAction.loadSettings]({ commit }): Promise<void> {
    const nodeId = await AppDataStorageService.get(ChartNodeIdStorageKey)
    const scaling = await AppDataStorageService.getBoolean(ChartScalingStorageKey)
    commit(ChartUiMutation.setSelectedNodeId, nodeId || null)
    commit(ChartUiMutation.setScaleSelected, scaling || false)
  },
  async [ChartUiAction.loadChartData]({ dispatch, state, getters, rootState }): Promise<void> {
    const input: ChartDataInput = {
      projectId: rootState.app.selectedProjectId,
      chartId: state.selectedChartId,
      period: state.selectedChartPeriod,
      dateFrom: state.selectedChartPeriodStartDateTime,
      dateTo: getNextPeriodStartUtc(state.selectedChartPeriodStartDateTime, state.selectedChartPeriod).toISOString(),
    }
    const nodeId = getters[ChartUiGetter.selectedNodeId]
    const scale = getters[ChartUiGetter.scaleSelected]
    const language = rootState.app.selectedLanguage
    await dispatch(`chart/${ChartAction.loadChartData}`, { input, nodeId, scale, language }, { root: true })
    await dispatch(ChartUiAction.configureChart)
  },
  async [ChartUiAction.selectChartCollection]({ commit, dispatch, getters }, chartCollectionId: string): Promise<void> {
    try {
      commit(ChartUiMutation.setLoading, true)
      if (!chartCollectionId) {
        const lastSelectedChartCollectionId = await AppDataStorageService.get(ChartCollectionIdStorageKey)
        const chartCollectionToSelect = getters[ChartUiGetter.chartCollections].find(c => c.id === lastSelectedChartCollectionId)
          || getters[ChartUiGetter.chartCollections][0]
        chartCollectionId = chartCollectionToSelect?.id ?? StatusViewChartCollection
      }
      const chartCollection = getters[ChartUiGetter.chartCollections].find(c => c.id === chartCollectionId)
      commit(ChartUiMutation.setSelectedChartCollectionId, chartCollectionId)
      await AppDataStorageService.set(ChartCollectionIdStorageKey, chartCollectionId)
      if (chartCollectionId === StatusViewChartCollection) {
        commit(ChartUiMutation.setSelectedChartCollectionId, chartCollection?.id ?? '')
        return
      }
      const lastSelectedChartId = await AppDataStorageService.get(ChartIdStorageKey)
      const chart = chartCollection?.charts.find(c => c.id === lastSelectedChartId) ?? chartCollection?.charts[0]
      await dispatch(ChartUiAction.selectChart, chart.id)
    } finally {
      commit(ChartUiMutation.setLoading, false)
    }
  },
  async [ChartUiAction.selectChart]({ commit, dispatch, state, getters }, id: string): Promise<void> {
    try {
      commit(ChartUiMutation.setLoading, true)
      const chart = getters[ChartUiGetter.chartCollections].find(cc => cc.charts.some(c => c.id === id))!.charts.find(c => c.id === id)!
      const existingPeriod = chart.periods.includes(state.selectedChartPeriod) ? state.selectedChartPeriod : chart.periods[0]
      commit(ChartUiMutation.setSelectedChartPeriod, existingPeriod)
      const periodStart = getPeriodStartUtc(state.selectedChartPeriodStartDateTime, state.selectedChartPeriod).toISOString()
      commit(ChartUiMutation.setSelectedChartId, id)
      await AppDataStorageService.set(ChartIdStorageKey, id)
      commit(ChartUiMutation.setSelectedChartPeriodStartDateTime, periodStart)
      await dispatch(ChartUiAction.loadChartData)
    } finally {
      commit(ChartUiMutation.setLoading, false)
    }
  },
  async [ChartUiAction.selectChartPeriod]({ commit, dispatch }, payload: { period: PeriodType, date: string }): Promise<void> {
    try {
      commit(ChartUiMutation.setLoading, true)
      const periodStart = getPeriodStartUtc(payload.date, payload.period).toISOString()
      commit(ChartUiMutation.setSelectedChartPeriod, payload.period)
      commit(ChartUiMutation.setSelectedChartPeriodStartDateTime, periodStart)
      await dispatch(ChartUiAction.loadChartData)
    } finally {
      commit(ChartUiMutation.setLoading, false)
    }
  },
  async [ChartUiAction.selectPreviousChartPeriod]({ commit, dispatch, state }): Promise<void> {
    try {
      commit(ChartUiMutation.setLoading, true)
      const previousPeriodStart = getPreviousPeriodStartUtc(state.selectedChartPeriodStartDateTime, state.selectedChartPeriod).toISOString()
      commit(ChartUiMutation.setSelectedChartPeriodStartDateTime, previousPeriodStart)
      await dispatch(ChartUiAction.loadChartData)
    } finally {
      commit(ChartUiMutation.setLoading, false)
    }
  },
  async [ChartUiAction.selectNextChartPeriod]({ commit, dispatch, state }): Promise<void> {
    try {
      commit(ChartUiMutation.setLoading, true)
      const nextPeriodStart = getNextPeriodStartUtc(state.selectedChartPeriodStartDateTime, state.selectedChartPeriod).toISOString()
      commit(ChartUiMutation.setSelectedChartPeriodStartDateTime, nextPeriodStart)
      await dispatch(ChartUiAction.loadChartData)
    } finally {
      commit(ChartUiMutation.setLoading, false)
    }
  },
  async [ChartUiAction.selectNodeId]({ commit }, nodeId: string): Promise<void> {
    commit(ChartUiMutation.setSelectedNodeId, nodeId)
    await AppDataStorageService.set(ChartNodeIdStorageKey, nodeId)
  },  
  async [ChartUiAction.selectScale]({ commit }, scale: boolean): Promise<void> {
    commit(ChartUiMutation.setScaleSelected, scale)
    await AppDataStorageService.setBoolean(ChartScalingStorageKey, scale)
  },  
  async [ChartUiAction.configureChart]({ commit, getters, rootState, rootGetters }): Promise<void> {
    const chartContext = {
      seriesVisible: (id: string) => !getters[ChartUiGetter.isSeriesHidden](id),
      seriesVisibilityToggled: (id: string) => commit(ChartUiMutation.toggleSeriesVisibility, id),
      resolveStringResource: (resourceKey: string) => rootGetters[`resource/${ResourceGetter.resolveStringResource}`](resourceKey),
    }
    const chartConfig = makeChartConfig(rootState.chart.chartData!, chartContext)
    const chart = Highcharts.chart('chart-container', chartConfig)
    chart.redraw()
  },
  async [ChartUiAction.loadReport]({ commit, dispatch, getters, state, rootState }): Promise<void> {
    try {
      commit(ChartUiMutation.setLoading, true)
      const projectId = rootState.app.selectedProjectId
      const chartId = state.selectedChartId
      const dateFrom = state.selectedChartPeriodStartDateTime
      const dateTo = getNextPeriodStartUtc(state.selectedChartPeriodStartDateTime, state.selectedChartPeriod).toISOString()
      const nodeId = getters[ChartUiGetter.selectedNodeId]
      const scale = getters[ChartUiGetter.scaleSelected]
      const language = rootState.app.selectedLanguage
      const projectName = rootState.plcOperation.projects.find(p => p.id === projectId)?.name
      const chartName = getters[ChartUiGetter.chartCollections]
        .find(cc => cc.charts.some(c => c.id === chartId))!
        .charts.find(c => c.id === chartId)!.text
      const fileName = `${projectName}-${chartName}-from-${dateFrom}-to-${dateTo}-${language}-export.csv`
      const input: ChartReportInput = {
        projectId,
        chartId,
        dateFrom,
        dateTo,
        fileName,
      }
      await dispatch(`chart/${ChartAction.loadReport}`, { input, nodeId, scale, language }, { root: true })
    } finally {
      commit(ChartUiMutation.setLoading, false)
    }
  },
  async [ChartUiAction.startTimer]({ commit }): Promise<void> {
    timerHandle = setInterval(() => commit(ChartUiMutation.setDateTimeUtcNow, moment.utc().toISOString()), TIMER_INTERVAL)
  },
  async [ChartUiAction.stopTimer](): Promise<void> {
    if (timerHandle) {
      clearInterval(timerHandle)
      timerHandle = null
    }
  },
}
