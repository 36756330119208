declare global {
  interface Window {
    cordova?: any,
  }
}

import { BackendEnvironmentTypes } from '@ecocoach/domain-store-modules/src/services/api.service'
import EnvironmentService from '@ecocoach/domain-store-modules/src/services/environment.service'
import { AuthenticationOptions } from '@ecocoach/domain-store-modules/src/authentication/models'

const B2CTENANTS = {
  [BackendEnvironmentTypes.PRODUCTION]: 'ecointegrationcloud',
  [BackendEnvironmentTypes.STAGING]: 'stagingauhtentication',
  [BackendEnvironmentTypes.DEVELOPMENT]: 'ecocoachdevelopmentauth',
}
const CLIENT_IDS = {
  [BackendEnvironmentTypes.PRODUCTION]: 'b031c102-6a76-48a9-9ba6-eb2a4726dfe6',
  [BackendEnvironmentTypes.STAGING]: '3eb09b38-81bb-43ef-a32a-446253ee8e79',
  [BackendEnvironmentTypes.DEVELOPMENT]: 'c39cc4df-9c69-49e4-9fdc-0685a030269d',
}
const MOBILE_APP_REDIRECT_URL = 'ecomobile://ecomobile'
const PLAY_STORE_ID = 'com.ecocoa.ch.mobile'
const APPLE_STORE_ID = 'id1401958490'

export function defaultBackendEnvironment() {
  if (window.location.origin.includes(BackendEnvironmentTypes.DEVELOPMENT)) {
    return BackendEnvironmentTypes.DEVELOPMENT
  }
  if (window.location.origin.includes(BackendEnvironmentTypes.STAGING)) {
    return BackendEnvironmentTypes.STAGING
  }
  return BackendEnvironmentTypes.PRODUCTION
}

export function authenticationRedirectUrl() {
  return window.cordova ? MOBILE_APP_REDIRECT_URL : window.location.origin
}

export function authenticationOptions(environment: BackendEnvironmentTypes, redirectCallback: (url: string) => void) {
  if (environment === BackendEnvironmentTypes.PRODUCTION || environment === BackendEnvironmentTypes.PREPRODUCTION) {
    return {
      clientId: CLIENT_IDS[BackendEnvironmentTypes.PRODUCTION],
      b2cTenant: B2CTENANTS[BackendEnvironmentTypes.PRODUCTION],
      redirectUrl: authenticationRedirectUrl(),
      redirectCallback,
      useRefreshToken: true,
      tokenEndpointProxyUrl: EnvironmentService.isRunningOnWeb ? 'https://ecocoachb2cproxy.azurewebsites.net/production' : undefined,
    } as AuthenticationOptions
  }
  if (environment === BackendEnvironmentTypes.STAGING) {
    return {
      clientId: CLIENT_IDS[BackendEnvironmentTypes.STAGING],
      b2cTenant: B2CTENANTS[BackendEnvironmentTypes.STAGING],
      redirectUrl: authenticationRedirectUrl(),
      redirectCallback,
      useRefreshToken: true,
      tokenEndpointProxyUrl: EnvironmentService.isRunningOnWeb ? 'https://ecocoachb2cproxy.azurewebsites.net/staging' : undefined,
    } as AuthenticationOptions
  }
  if (environment === BackendEnvironmentTypes.DEVELOPMENT) {
    return {
      clientId: CLIENT_IDS[BackendEnvironmentTypes.DEVELOPMENT],
      b2cTenant: B2CTENANTS[BackendEnvironmentTypes.DEVELOPMENT],
      redirectUrl: authenticationRedirectUrl(),
      redirectCallback,
      useRefreshToken: true,
      tokenEndpointProxyUrl: EnvironmentService.isRunningOnWeb ? 'https://ecocoachb2cproxy.azurewebsites.net/development' : undefined,
    } as AuthenticationOptions
  }
}

export function openInStore() {
  if (EnvironmentService.isRunningOnAndroid) {
    window.cordova!.plugins.market.open(PLAY_STORE_ID)
  } else if (EnvironmentService.isRunningOnIos) {
    window.cordova!.plugins.market.open(APPLE_STORE_ID)
  }
}
