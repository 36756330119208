import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AlarmLogUiGetter, AlarmLogUiState } from '@/store/modules/alarmLogUi/types'
import { CustomPlcAlarmViewModel } from '@/store/modules/alarmLogUi/models'
import { sortedCustomPlcAlarms } from '@/store/modules/alarmLogUi/helpers'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'

export const getters: GetterTree<AlarmLogUiState, RootState> = {
  [AlarmLogUiGetter.isInteracted]({ interacted }): boolean {
    return interacted
  },
  [AlarmLogUiGetter.alarms](_, __, rootState, rootGetters): CustomPlcAlarmViewModel[] {
    const resolveStringResource = rootGetters[`resource/${ResourceGetter.resolveStringResource}`]
    return sortedCustomPlcAlarms(rootState.alarm.customPlcAlarms)
      .reverse()
      .map(alarm => {
        const control = rootState.plcOperation.controlsLookup.get(alarm.controlId)
        const device = rootState.plcOperation.devicesLookup.get(control?.deviceId!)
        return {
          ...alarm,
          displayName: alarm.alarmDefinitionName || resolveStringResource(alarm.nameResourceId),
          displayMessage: alarm.alarmDefinitionMessage || resolveStringResource(alarm.messageResourceId),
          deviceId: device?.id ?? '',
        } as CustomPlcAlarmViewModel
      })
  },
}
