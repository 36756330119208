import { PeriodType } from '@ecocoach/domain-store-modules/src/chart/models'

export interface ChartUiState {
  isLoading: boolean,
  selectedChartCollectionId: string
  selectedChartId: string
  selectedChartPeriod: PeriodType
  selectedChartPeriodStartDateTime: string
  selectedNodeId: string | null
  scaleSelected: boolean
  hiddenSeriesIds: string[]
  dateTimeUtcNow: string
}

export enum ChartUiAction {
  loadSettings = 'loadSettings',
  loadChartData = 'loadChartData',
  selectChartCollection = 'selectChartCollection',
  selectChart = 'selectChart',
  selectChartPeriod = 'selectChartPeriod',
  selectPreviousChartPeriod = 'selectPreviousChartPeriod',
  selectNextChartPeriod = 'selectNextChartPeriod',
  selectNodeId = 'selectNodeId',
  selectScale = 'selectScale',
  configureChart = 'configureChart',
  loadReport = 'loadReport',
  startTimer = 'startTimer',
  stopTimer = 'stopTimer',
}

export enum ChartUiMutation {
  setLoading = 'setLoading',
  setSelectedChartCollectionId = 'setSelectedChartCollectionId',
  setSelectedChartId = 'setSelectedChartId',
  setSelectedChartPeriod = 'setSelectedChartPeriod',
  setSelectedChartPeriodStartDateTime = 'setSelectedChartPeriodStartDateTime',
  setSelectedNodeId = 'setSelectedNodeId',
  setScaleSelected = 'setScaleSelected',
  toggleSeriesVisibility = 'toggleSeriesVisibility',
  setDateTimeUtcNow = 'setDateTimeUtcNow',
}

export enum ChartUiGetter {
  isLoading = 'isLoading',
  chartCollections = 'chartCollections',
  selectedChartCollectionId = 'selectedChartCollectionId',
  selectedChartId = 'selectedChartId',
  selectedNodeId = 'selectedNodeId',
  selectedChartPeriod = 'selectedChartPeriod',
  scaleSelected = 'scaleSelected',
  selectedChartPeriodStartDateTime = 'selectedChartPeriodStartDateTime',
  canSelectNextChartPeriod = 'canSelectNextChartPeriod',
  displayedChartPeriod = 'displayedChartPeriod',
  selectedChartPeriodLocalDate = 'selectedChartPeriodLocalDate',
  showNodeSelection = 'showNodeSelection',
  showScaleSelection = 'showScaleSelection',
  showSettings = 'showSettings',
  nodeOptions = 'nodeOptions',
  isSeriesHidden = 'isSeriesHidden',
}
