<template>
  <v-layout row align-center justify-center class="multi-day-picker">
    <v-flex v-for="option in options" class="day-option"
      :key="option.value"
      :class="{ 'selected': isSelectedValue(option.value)}"
    >
      <v-btn fab small class="day-button"
        :class="{ 'selected': isSelectedValue(option.value)}"
        @click="onClick(option.value)"
      >
        {{option.label}}
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class MultiDayPicker extends Vue {
  @Prop() public options: Array<{value: string, label: string }>
  @Prop() public selectedValues: string[]

  @Emit('selected')
  public onClick(value: string) {
    const allValues = this.options.map(o => o.value)
    if (this.selectedValues.find(v => v === value)) {
      return allValues.filter(v => this.selectedValues.includes(v) && v !== value)
    } else {
      return allValues.filter(v => this.selectedValues.includes(v) || v === value)
    }
  }

  public isSelectedValue(value: string) {
    return this.selectedValues.includes(value)
  }
}
</script>
<style lang='scss' scoped>
.multi-day-picker {
  .day-option {
    // height: 35px;
    // width: 35px;
    // border-radius: 5px;
    margin: 0px;
    padding: 0px;
    .day-button {
      margin: 2px;
      background-color: #7a8b9a;
      text-transform: inherit;
      &.selected {
        background-color: #fa0f36;
      }
      button: {

      }
    }
  }
}
</style>
    
