<template>
  <app-view>
    <icon-header
      :title="userAgreement.name"
      material-icon="menu_book"
    />
    <user-agreement-content :content="userAgreement.content"/> 
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import UserAgreementContent from './shared/UserAgreementContent.vue'
import { UserAgreementViewModel } from '../store/modules/app/models'

const App = namespace('app')

@Component({
  components: {
    AppView,
    IconHeader,
    UserAgreementContent,
  },
})
export default class UserAgreement extends Vue {
  @App.Getter public userAgreements: UserAgreementViewModel[]

  public get userAgreementType(): string {
    return this.$route.params.type
  }

  public get userAgreement(): UserAgreementViewModel {
    return this.userAgreements.find((_) => _.type === this.userAgreementType)!
  }
}
</script>

<style lang="scss" scoped>
</style>
