<template>
  <app-view>
    <list-navigation-item
      icon="eco.home.white"
      label="navigation.houses"
      :value="selectedProjectName"
      @selected="navigateTo('projects')"
    />
    <list-navigation-item
      v-if="hasDemoProjects"
      material-icon="rule"
      label="settings.account.demoprojects"
      :icon-badge="demoProjectsBadge"
      :value="
        includeDemoProjects
          ? 'common.button.yes'
          : 'common.button.no' | translate
      "
      @selected="navigateTo('demoprojects')"
    />
    <list-navigation-item
      material-icon="alarm"
      label="navigation.alarms"
      @selected="navigateTo('alarmsOverview')"
    />
    <list-navigation-item
      icon="eco.account.white"
      label="navigation.account"
      :value="userIdentifier"
      @selected="navigateTo('account')"
    />
    <list-navigation-item
      icon="eco.helpcontent.white"
      label="navigation.support"
      @selected="navigateTo('support')"
    />
    <list-navigation-item
      icon="eco.information.white"
      label="navigation.information"
      :icon-badge="updateBadge"
      :value="currentVersion"
      @selected="navigateTo('about')"
    />
    <list-navigation-item
      material-icon="menu_book"
      label="navigation.useragreements"
      @selected="navigateTo('useragreements')"
    />
    <list-navigation-item
      v-if="showDeveloperArea"
      material-icon="bug_report"
      label="Developer"
      :value="selectedBackendEnvironment"
      @selected="navigateTo('developer')"
    />
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import ListNavigationItem from './shared/ListNavigationItem.vue'
import { Services } from '@ecocoach/domain-store-modules'
import StackRouter from '../router'
import EnvironmentService from '../../../eco-domain-store-modules/src/services/environment.service'
import { ProjectModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'

const App = namespace('app')
const DeviceUi = namespace('deviceUi')

@Component({
  components: {
    AppView,
    IconHeader,
    ListNavigationItem,
  },
})
export default class More extends Vue {
  @App.Getter public version: string
  @App.Getter public updateAvailable: boolean
  @App.Getter public selectedProject: ProjectModel | null
  @App.Getter public projects: ProjectModel[]
  @App.Getter public hasDemoProjects: boolean
  @App.Getter public includeDemoProjects: boolean | null
  @App.Getter public isEcocoachEmployee: boolean
  @App.Getter public userIdentifier: string
  @DeviceUi.Action public stopControlUpdates: () => Promise<void>

  public async mounted() {
    this.stopControlUpdates()
  }

  public get selectedProjectName() {
    return this.selectedProject?.name ?? ''
  }

  public get demoProjectsBadge() {
    const showBadge = !this.projects.length && this.includeDemoProjects === null
    return showBadge ? '1' : ''
  }

  public get updateBadge() {
    return this.updateAvailable ? '1' : ''
  }

  public get currentVersion(): string {
    return `v${this.version}`
  }

  public get showDeveloperArea() {
    return this.isEcocoachEmployee || EnvironmentService.isRunningLocally
  }

  public get selectedBackendEnvironment(): string {
    return Services.ApiService.selectedBackendEnvironment()
  }

  public navigateTo(routeName: string) {
    Vue.$log.info('Navigating to', routeName)
    StackRouter.navigate({ name: routeName })
  }
}
</script>
<style lang="scss" scoped></style>
