<template>
  <app-view>
    <h1>Not found</h1>
    {{ message }}
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import AppView from './AppView.vue'

@Component({
  components: {
    AppView,
  },
})
export default class NotFound extends Vue {
  public mounted() {
    Vue.$log.warn('page not found', this.$route.path)
  }

  public get message(): string {
    return this.$route.params.id || this.$route.path
  }
}
</script>
