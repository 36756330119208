<!-- eslint-disable indent -->
<template>
  <app-view
    :top-margin="0">
    <v-layout column fill-height class="energy-view">
      <div class="chart-collections">
        <navigation-bar
          :links="chartCollections"
          :active-link="selectedChartCollectionId"        
          :blocked="isLoading"
          @goToLink="selectChartCollection"
        />
      </div>
      <div class="charts-selection" v-if="charts.length > 0">
        <tab-headers 
          :tabs="charts"
          :selected-tab="selectedChartId"
          :disabled="isLoading"
          @changed="selectChart"
        />
      </div>
      <div class="chart" v-if="chart">
        <div class="chart-title">
          {{ chart.text }}
        </div>
        <v-layout row align-center justify-center class="chart-date">
          <v-flex v-if="showDownload || showSettings" grow class="actions left">
          </v-flex>
          <v-flex shrink>
            <icon-button
              @buttonClicked="selectPreviousChartPeriod"
              type="materialIcon"
              materialIconName="chevron_left"
              :height="20"
              :width="20"
              :enabled="!isLoading"
            />
          </v-flex>
          <v-flex shrink @click="onShowDatePicker" class="current-chart-period" :class="{ disabled: isLoading }">
            {{ displayedChartPeriod  }}
          </v-flex>
          <v-flex shrink>
            <icon-button
              @buttonClicked="selectNextChartPeriod"
              type="materialIcon"
              materialIconName="chevron_right"
              :height="20"
              :width="20"
              :enabled="!isLoading && canSelectNextChartPeriod"
            />
          </v-flex>
          <v-flex v-if="showDownload || showSettings" grow class="actions right">
            <!-- only visible on large screens (otherwise settings icon with dialog) -->
            <chart-settings-inline class="hidden-md-and-down" v-if="showSettings"/>
            <!-- only visible in browser -->
            <icon-button v-if="showDownload"
              @buttonClicked="loadReport"
              type="materialIcon"
              materialIconName="download"
              :height="20"
              :width="20"
              :enabled="!isLoading"
              class="settings-icon"
            />
            <!-- only visible on small screens (otherwise inline settings bar) -->
            <icon-button v-if="showSettings"
            class="settings-icon hidden-lg-and-up"
              @buttonClicked="showSettingsDialog = true"
              type="materialIcon"
              materialIconName="settings"
              :height="20"
              :width="20"
              :enabled="!isLoading"
            />
          </v-flex>
        </v-layout>
        <time-period-picker-dialog-next
          :show="showDatePicker"
          :periods="periods"
          :default-period="selectedChartPeriod"
          :default-date="selectedChartPeriodLocalDate"
          :language="selectedLanguage"
          @selected="onDatePicked"
          @close="showDatePicker = false"
        />
        <chart-settings-dialog
          :show="showSettingsDialog"
          @closed="onSettingsClosed"
        />
      </div>
      <energy-status-view v-if="selectedChartCollectionId === 'Status'"
         :model="energyStatusViewModel"
         @selected="navigateToDevice"
      />
      <div class="chart-parent" v-else>
        <base-spinner class="spinner" v-if="isLoading" />
        <div id="chart-container" v-show="!isLoading" />
      </div>
    </v-layout>
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import TabHeaders from './shared/TabHeaders.vue'
import ChartSettingsDialog from './shared/ChartSettingsDialog.vue'
import ChartSettingsInline from './shared/ChartSettingsInline.vue'
import TimePeriodPickerDialogNext from './shared/TimePeriodPickerDialogNext.vue'
import { ChartCollectionTab, ChartTab } from '../store/modules/chartUi/models'
import moment from 'moment'
import EnergyStatusView from '../../../shared-components/src/components/energyStatusView/EnergyStatusView.vue'
import { EnergyStatusViewModel } from '../../../shared-components/src/components/energyStatusView/models'
import StackRouter from '../router'
import { PeriodType } from '../../../eco-domain-store-modules/src/chart/models'
import EnvironmentService from '../../../eco-domain-store-modules/src/services/environment.service'

const EnergyStatusUi = namespace('energyStatusUi')
const ChartUi = namespace('chartUi')
const Resource = namespace('resource')
const App = namespace('app')
const DeviceUi = namespace('deviceUi')

@Component({
  components: {
    AppView,
    ChartSettingsDialog,
    ChartSettingsInline,
    EnergyStatusView,
    TabHeaders,
    TimePeriodPickerDialogNext,
  },
})
export default class EnergyNext extends Vue {
  @EnergyStatusUi.Getter public energyStatusViewModel: EnergyStatusViewModel
  @ChartUi.Getter public chartCollections: ChartCollectionTab[]
  @ChartUi.Getter public selectedChartCollectionId: string
  @ChartUi.Getter public selectedChartId: string
  @ChartUi.Getter public selectedChartPeriod: string
  @ChartUi.Getter public canSelectNextChartPeriod: boolean
  @ChartUi.Getter public displayedChartPeriod: string
  @ChartUi.Getter public selectedChartPeriodLocalDate: string
  @ChartUi.Getter public showSettings: boolean
  @ChartUi.Getter public isLoading: boolean
  @ChartUi.Action public loadSettings: () => Promise<void>
  @ChartUi.Action public selectChartCollection: (chartCollectionId: string) => Promise<void>
  @ChartUi.Action public selectChart: (id: string) => Promise<void>
  @ChartUi.Action public selectChartPeriod: (payload: { period: PeriodType, date: string }) => Promise<void>
  @ChartUi.Action public selectPreviousChartPeriod: () => Promise<void>
  @ChartUi.Action public selectNextChartPeriod: () => Promise<void>
  @ChartUi.Action public loadReport: () => Promise<void>
  @ChartUi.Action public startTimer: () => Promise<void>
  @ChartUi.Action public stopTimer: () => Promise<void>
  @Resource.Getter public dictionary
  @App.Getter public selectedLanguage: string
  @DeviceUi.Action public stopControlUpdates: () => Promise<void>
  @EnergyStatusUi.Action public startStatusViewUpdates: () => Promise<void>
  @EnergyStatusUi.Action public stopStatusViewUpdates: () => Promise<void>

  public showDatePicker = false

  public showSettingsDialog = false

  public beforeMount() {
    this.startTimer()
  }

  public async mounted() {
    await this.stopControlUpdates()
    await this.startStatusViewUpdates()
    // select default chart collection
    await this.loadSettings()
    await this.selectChartCollection('')
  }

  public beforeDestroy() {
    this.stopTimer()
    this.stopStatusViewUpdates()
  }

  public get showDownload() {
    return EnvironmentService.isRunningOnWeb
  }

  public get charts(): ChartTab[] {
    const selectedChartCollection = this.chartCollections.find(c => c.id === this.selectedChartCollectionId)
    return selectedChartCollection?.charts ?? []
  }

  public get chart(): ChartTab {
    if (!this.selectedChartId) {
      return this.charts[0]
    }
    return this.charts.find(c => c.id === this.selectedChartId)!
  }

  public get periods() {
    return this.chart?.periods ?? []
  }

  public onShowDatePicker() {
    if (!this.isLoading) {
      this.showDatePicker = true
    }
  }

  public onDatePicked(payload: { period: PeriodType, date: string }) {
    this.showDatePicker = false
    this.selectChartPeriod({
      period: payload.period,
      date: moment(payload.date).utc().toISOString(),
    })
  }

  public onSettingsClosed(changed: boolean){
    this.showSettingsDialog = false
    // reload chart if changed
    if (changed) {
      this.selectChart(this.selectedChartId)
    }
  }

  public navigateToDevice(deviceId: string) {
    Vue.$log.info('Navigating to energydevice', deviceId)
    StackRouter.navigate({ name: 'energydevicenext', params: { deviceId } })
  }
}
</script>

<style lang='scss' scoped>
.energy-view {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.chart-collections {
  height: 60px; // from component given
  font-size: 10px;
}
.charts-selection {
  min-height: 35px!important;
  height: 35px!important;
  width: 100%!important;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; 
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.charts-selection::-webkit-scrollbar {
  display: none;
}
.chart {
  .chart-title {
    padding: 10px 5px 5px 5px;
    font-size: large;
    font-weight: 400;
  }
  .chart-date {
    .actions, .actions {
      flex: 1 1 0px;
      display: flex;
      align-items: center;
      &.left {
        justify-content: flex-start;
      }
      &.right {
        justify-content: flex-end;
      }
    }
    .current-chart-period {
      border: 1px solid white;
      padding: 5px 8px;
      cursor: pointer;
      &.disabled {
        color: $fore-color-disabled;
        border-color: $fore-color-disabled;
      }
    }    
  }
  .chart-date-pickers {
    .tab {
      background: linear-gradient(#1f3c5e,#1c385a);
    }
  }
}
.settings-icon {
  margin: 0px 8px 0px 0px;
}
.chart-parent {
  width: 100%;
  height: 100%;
  #chart-container {
    width: 100%;
    height: 100%;
  }
}
.spinner {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
<style lang="css">
.v-snack__content {
  padding: 10px !important;
}
.chart-collections .navigationbar button.link {
  background: linear-gradient(180deg, #12273f, #09162a);
}
.chart-collections .navigationbar button:not(:last-child) {
  border-right: 1px solid #0f1820;
}
.chart-collections .navigationbar button.link.active {
  background: linear-gradient(180deg, #fe0036, #77032c);
}
.chart-collections .navigationbar button.link .link__content span {
  font-weight: inherit !important;
  font-size: 12px;
  text-transform: inherit;
}
</style>
