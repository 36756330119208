import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { AppState, AppAction, AppMutation, AppGetter } from './types'
import Vue from 'vue'
import { IncludeDemoProjectsStorageKey, LanguageStorageKey, ProjectIdStorageKey, PushNotificationOpenedPayload, PushNotificationType, ToastModel } from './models'
import { PlcOperationAction } from '@ecocoach/domain-store-modules/src/plcOperation/types'
import { ResourceAction, ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { AlarmAction } from '@ecocoach/domain-store-modules/src/alarm/types'
import { ChartAction } from '@ecocoach/domain-store-modules/src/chart/types'
import { UserAgreementAction } from '@ecocoach/domain-store-modules/src/userAgreement/types'
import EnvironmentService from '@ecocoach/domain-store-modules/src/services/environment.service'
import api from './api'
import { AuthenticationAction, AuthenticationGetter } from '@ecocoach/domain-store-modules/src/authentication/types'
import { ApiErrorConverterResult } from '@ecocoach/domain-store-modules/src/helpers/apiErrorConverter'
import AppDataStorageService from '@ecocoach/domain-store-modules/src/services/appdatastorage.service'
import moment from 'moment'
import { ResourceCategory } from '@ecocoach/domain-store-modules/src/resource/models'
import { controlStateHubId, plcStateHubId } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { delay } from '@ecocoach/domain-store-modules/src/utils'
import { alarmStateHubId } from '@ecocoach/domain-store-modules/src/alarm/models'
import { EnergyStatusAction } from '@ecocoach/domain-store-modules/src/energyStatus/types'
import { energyStatusHubId } from '../../../../../eco-domain-store-modules/src/energyStatus/models'
import { UserSettingsAction } from '@ecocoach/domain-store-modules/src/userSettings/types'

declare const navigator: any

const resourceCategories = [
  ResourceCategory.APP_STRINGS,
  ResourceCategory.APP_ICONS,
  ResourceCategory.DEVICE_ICONS,
  ResourceCategory.ROOM_ICONS,
  ResourceCategory.SCENE_ICONS,
  ResourceCategory.CHART_ICONS,
  ResourceCategory.SYSTEM_CONFIGURATION,
]

export const actions: ActionTree<AppState, RootState> = {
  async [AppAction.load]({ commit, dispatch }): Promise<void> {
    try {
      commit(AppMutation.setLoading, true)
      if (navigator.splashscreen) {
        Vue.$log.debug('hiding splashscreen')
        navigator.splashscreen.hide()
      }
      await dispatch(AppAction.loadApiData)
      // select default project
      await dispatch(AppAction.selectProject, '')
    } catch (error) {
      Vue.$log.error(AppAction.load, error)
    } finally {
      commit(AppMutation.setReady, true)
      commit(AppMutation.setLoading, false)
    }
  },
  async [AppAction.pause]({ commit, dispatch }): Promise<void> {
    commit(AppMutation.setReady, false)
    return await dispatch(AppAction.disconnectHubs)
  },
  async [AppAction.resume]({ commit, dispatch, rootGetters }): Promise<void> {
    try {
      commit(AppMutation.setLoading, true)
      await dispatch(`authentication/${AuthenticationAction.refreshToken}`, null, { root: true })
      if (!rootGetters[`authentication/${AuthenticationGetter.loggedIn}`]) {
        Vue.$log.error(AppAction.resume, 'not logged in after refresh token')
        return
      }
      await dispatch(AppAction.loadApiData)
      dispatch(AppAction.connectHubs).then(() => dispatch(AppAction.invokeHubs))
    } catch (error) {
      Vue.$log.error(AppAction.resume, error)
    } finally {
      commit(AppMutation.setReady, true)
      commit(AppMutation.setLoading, false)
    }
  },
  async [AppAction.loadVersionInfo]({ commit }): Promise<void> {
    if (!EnvironmentService.isRunningOnWeb) {
      try {
        const data = await api.appVersionInfo()
        commit(AppMutation.setVersionInfo, data)
      } catch (error) {
        Vue.$log.error(AppAction.loadVersionInfo, error)
      }
    }
  },
  async [AppAction.loadApiData]({ dispatch, getters }): Promise<void> {
    try {
      const language = getters.selectedLanguage
      await Promise.all([
        dispatch(AppAction.loadVersionInfo),
        dispatch(`userAgreement/${UserAgreementAction.loadCurrentUserAgreements}`, null, { root: true}),
        dispatch(`userSettings/${UserSettingsAction.loadMfaConfiguration}`, null, { root: true }),
        dispatch(`userAgreement/${UserAgreementAction.loadAcceptedUserAgreements}`, null, { root: true}),
        dispatch(`plcOperation/${PlcOperationAction.loadProjects}`, { includeDemoProjects: true }, { root: true}),
        dispatch(`resource/${ResourceAction.loadResources}`, { language, categories: resourceCategories }, { root: true}),
      ])
    } catch (error) {
      Vue.$log.error(AppAction.loadApiData, error)
    }
  },
  async [AppAction.connectHubs]({ dispatch }): Promise<void> {
    try {
      await Promise.all([
        dispatch(`alarm/${AlarmAction.connectToAlarmStateHub}`, false, { root: true }),
        dispatch(`plcOperation/${PlcOperationAction.connectToControlStateHub}`, null, { root: true }),
        dispatch(`energyStatus/${EnergyStatusAction.connectHub}`, null, { root: true }),
        dispatch(`plcOperation/${PlcOperationAction.connectToPlcStateHub}`, null, { root: true }),
      ])
    } catch (error) {
      Vue.$log.error(AppAction.connectHubs, error)
    }
  },
  async [AppAction.disconnectHubs]({ dispatch }): Promise<void> {
    try {
      await Promise.all([
        dispatch(`alarm/${AlarmAction.disconnectFromAlarmStateHub}`, null, { root: true }),
        dispatch(`plcOperation/${PlcOperationAction.disconnectFromControlStateHub}`, null, { root: true }),
        dispatch(`energyStatus/${EnergyStatusAction.disconnectHub}`, null, { root: true }),
        dispatch(`plcOperation/${PlcOperationAction.disconnectFromPlcStateHub}`, null, { root: true }),
      ])
    } catch (error) {
      Vue.$log.error(AppAction.disconnectHubs, error)
    }
  },
  async [AppAction.invokeHubs]({ dispatch, state }): Promise<void> {
    const projectId = state.selectedProjectId
    if (projectId) {
      dispatch(`plcOperation/${PlcOperationAction.startNotifications}`, null, { root: true })
      dispatch(`energyStatus/${EnergyStatusAction.startNotifications}`, null, { root: true })
      dispatch(`alarm/${AlarmAction.startForProject}`, projectId, { root: true })
      dispatch(`plcOperation/${PlcOperationAction.startPlcStateStateHubForProject}`, projectId, { root: true })
    }
  },
  async [AppAction.selectProject]({ commit, dispatch, getters }, projectId: string): Promise<void> {
    try {
      commit(AppMutation.setLoading, true)
      if (!projectId) {
        const lastSelectedProjectId = await AppDataStorageService.get(ProjectIdStorageKey)
        const projectToSelect = getters[AppGetter.projects].find(p => p.id === lastSelectedProjectId)
          || getters[AppGetter.projects][0]
        projectId = projectToSelect && projectToSelect.id || ''
      }
      commit(AppMutation.selectProject, projectId)
      const language: string = getters[AppGetter.selectedLanguage]

      if (projectId) {
        await Promise.all([
          dispatch(`plcOperation/${PlcOperationAction.loadConfigurationByProjectId}`, { projectId, language }, { root: true }),
          dispatch(`plcOperation/${PlcOperationAction.loadFavorites}`, projectId, { root: true }),
          dispatch(`plcOperation/${PlcOperationAction.loadScenes}`, projectId, { root: true }),
          dispatch(`energyStatus/${EnergyStatusAction.loadEnergyStatusData}`, { projectId, language }, {root: true}),
          dispatch(`chart/${ChartAction.loadChartCollections}`, { projectId, language }, {root: true}),
        ])
        await AppDataStorageService.set(ProjectIdStorageKey, projectId)
      }
      dispatch(AppAction.connectHubs).then(() => dispatch(AppAction.invokeHubs))
    } catch (error) {
      Vue.$log.error(AppAction.selectProject, error)
    } finally {
      commit(AppMutation.setLoading, false)
    }
  },
  async [AppAction.acceptUserAgreement]({ commit, dispatch, rootState }, payload: string): Promise<void> {
    try {
      commit(AppMutation.setInteracted, true)
      const userAgreement = rootState.userAgreement.currentUserAgreements.find(doc => doc.documentType === payload)!
      await dispatch(`userAgreement/${UserAgreementAction.acceptUserAgreement}`, {
        documentType: userAgreement.documentType,
        documentVersion: userAgreement.documentVersion,
      }, { root: true })
    } finally {
      commit(AppMutation.setInteracted, false)
    }
  },
  async [AppAction.selectLanguage]({ commit }, language: string): Promise<void> {
    await AppDataStorageService.set(LanguageStorageKey, language)
    moment.locale(language)
    commit(AppMutation.selectLanguage, language)
  },
  async [AppAction.setIncludeDemoProjects]({ commit, dispatch, getters }, includeDemoProjects: boolean): Promise<void> {
    await AppDataStorageService.setBoolean(IncludeDemoProjectsStorageKey, includeDemoProjects)
    commit(AppMutation.setIncludeDemoProjects, includeDemoProjects)

    // update project selection if necessary
    if (!getters[AppGetter.projects].find(p => p.id === getters[AppGetter.selectedProjectId])) {
      await dispatch(AppAction.selectProject, '')
    }
  },
  async [AppAction.initiateAccountDeletion]({ commit }): Promise<void> {
    try {
      commit(AppMutation.setInteracted, true)
      await api.initiateAccountDeletion()
    } finally {
      commit(AppMutation.setInteracted, false)
    }
  },
  async [AppAction.setEmailMfaEnabled]({ commit, dispatch }, payload: boolean): Promise<void> {
    try {
      commit(AppMutation.setInteracted, true)
      await dispatch(`userSettings/${UserSettingsAction.setEmailMfaEnabled}`, payload, { root: true })
    } finally {
      commit(AppMutation.setInteracted, false)
    }
  },
  async [AppAction.toastInfo]({ commit }, message: string): Promise<void> {
    commit(AppMutation.setToast, ToastModel.info(message))
  },
  async [AppAction.toastWarning]({ commit }, message: string): Promise<void> {
    commit(AppMutation.setToast, ToastModel.warning(message))
  },
  async [AppAction.toastError]({ commit }, message: string): Promise<void> {
    commit(AppMutation.setToast, ToastModel.error(message))
  },
  async [AppAction.hideToast]({ commit }): Promise<void> {
    commit(AppMutation.setToast, null)
  },
  async [AppAction.handleApiError]({ dispatch }, payload: ApiErrorConverterResult): Promise<void> {
    const message = [payload.message, ...payload.details].join('<br>')
    dispatch(AppAction.toastError, message)
  },
  async [AppAction.handleHubReconnecting]({ dispatch, rootGetters }, hubId: string): Promise<void> {
    const stringResource = rootGetters[`resource/${ResourceGetter.dictionary}`]
    if (hubId === controlStateHubId) {
      dispatch(AppAction.toastWarning,  stringResource('error.no.connection'))
    }
  },
  async [AppAction.handleHubReconnected]({ dispatch, state, rootGetters }, hubId: string): Promise<void> {
    const stringResource = rootGetters[`resource/${ResourceGetter.dictionary}`]
    const projectId = state.selectedProjectId
    if (hubId === controlStateHubId) {
      dispatch(AppAction.toastInfo, stringResource('connection.reconnected'))
      if (projectId) {
        dispatch(`plcOperation/${PlcOperationAction.startNotifications}`, null, { root: true })
      }
    } else if (hubId === energyStatusHubId) {
      if (projectId) {
        dispatch(`energyStatus/${EnergyStatusAction.startNotifications}`, null, { root: true })
      }
    } else if (hubId === alarmStateHubId) {
      if (projectId) {
        dispatch(`alarm/${AlarmAction.startForProject}`, projectId, { root: true })
      }
    } else if (hubId === plcStateHubId) {
      if (projectId) {
        dispatch(`plcOperation/${PlcOperationAction.startPlcStateStateHubForProject}`, projectId, { root: true })
      }
    }
  },
  async [AppAction.handlePushNotificationOpened]({ dispatch, state, rootState, getters }, payload: PushNotificationOpenedPayload): Promise<void> {
    // wait until app is fully loaded or resumed
    while (!state.ready) {
      await delay(100)
    }

    const hasUnacceptedUserAgreements = getters[AppGetter.userAgreements].some(_ => !_.accepted)
    if (hasUnacceptedUserAgreements) {
      return
    }

    await dispatch(AppAction.selectProject, payload.projectId)
    if (payload.notificationType === PushNotificationType.CustomAlarmOpened || payload.notificationType === PushNotificationType.CustomAlarmClosed) {
      const control = rootState.plcOperation.controlsLookup.get(payload.objectId)
      const device = rootState.plcOperation.devicesLookup.get(control?.deviceId!)
      if (device) {
        payload.navigate({ name: 'alarmdevice', params: { deviceId: device.id } }, ['/more/', '/more/alarmsOverview'])
      }
    }
    if (payload.notificationType === PushNotificationType.CustomAlarmOfflineClosed) {
      payload.navigate({ name: 'alarmsOverview' }, ['/more/'])
    }
  },
}
