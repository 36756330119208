import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { DeviceUiAction, DeviceUiMutation } from './types'
import { PlcOperationAction, PlcOperationMutation } from '@ecocoach/domain-store-modules/src/plcOperation/types'
import { AppGetter, AppMutation } from '../app/types'
import { AppDisplayLevels, ControlModelV2 } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { appDisplayLevel } from '@/store/utils'
import { ControlTypeV2 } from '@ecocoach/domain-store-modules/src/common'
import { delay } from '@ecocoach/domain-store-modules/src/utils'

const DelayAfterStartNotifiationsInMs = 100 // give the plc some time to send the current values of the control states to notify

export const actions: ActionTree<{}, RootState> = {
  async [DeviceUiAction.startControlUpdatesForFavorites]({ commit, dispatch, rootState, rootGetters }): Promise<void> {
    try {
      commit(`app/${AppMutation.setInteracted}`, true, { root: true })
      commit(DeviceUiMutation.setLoading, true)
      const isEcocoachEmployee = rootGetters[`app/${AppGetter.isEcocoachEmployee}`]
      const appDisplayLevels = [
        AppDisplayLevels.DISPLAYED_ON_FIRST_LEVEL,
        AppDisplayLevels.DISPLAYED_ON_SECOND_LEVEL,
      ]
      const deviceIds = rootState.plcOperation.favorites
      const filter = (control: ControlModelV2) => {
        const deviceFilter = (c: ControlModelV2) => deviceIds.includes(c.deviceId)
        const ecocoachFilter = (c: ControlModelV2) => isEcocoachEmployee || !c?.attributes?.ecocoachOnly
        const appDisplayLevelFilter = (c: ControlModelV2) => appDisplayLevels.includes(appDisplayLevel(c))
        return deviceFilter(control) && appDisplayLevelFilter(control) && ecocoachFilter(control)
      }
      await dispatch(`plcOperation/${PlcOperationAction.setControlsFilter}`, filter, { root: true })
      await delay(DelayAfterStartNotifiationsInMs)
    } finally {
      commit(`app/${AppMutation.setInteracted}`, false, { root: true })
      commit(DeviceUiMutation.setLoading, false)
    }
  },
  async [DeviceUiAction.startControlUpdatesForRoom]({ commit, dispatch, rootState, rootGetters }, roomId: string): Promise<void> {
    try {
      commit(`app/${AppMutation.setInteracted}`, true, { root: true })
      commit(DeviceUiMutation.setLoading, true)
      const isEcocoachEmployee = rootGetters[`app/${AppGetter.isEcocoachEmployee}`]
      const appDisplayLevels = [
        AppDisplayLevels.DISPLAYED_ON_FIRST_LEVEL,
        AppDisplayLevels.DISPLAYED_ON_SECOND_LEVEL,
      ]      
      const deviceIds = rootState.plcOperation.devicesForRoomLookup.get(roomId)?.map(d => d.id) ?? []
      const filter = (control: ControlModelV2) => {
        const deviceFilter = (c: ControlModelV2) => deviceIds.includes(c.deviceId)
        const ecocoachFilter = (c: ControlModelV2) => isEcocoachEmployee || !c?.attributes?.ecocoachOnly
        const appDisplayLevelFilter = (c: ControlModelV2) => appDisplayLevels.includes(appDisplayLevel(c))
        return deviceFilter(control) && appDisplayLevelFilter(control) && ecocoachFilter(control)
      }
      await dispatch(`plcOperation/${PlcOperationAction.setControlsFilter}`, filter, { root: true })
      await delay(DelayAfterStartNotifiationsInMs)
    } finally {
      commit(`app/${AppMutation.setInteracted}`, false, { root: true })
      commit(DeviceUiMutation.setLoading, false)
    }
  },
  async [DeviceUiAction.startControlUpdatesForDevice]({ commit, dispatch, rootGetters }, deviceId: string): Promise<void> {
    try {
      commit(`app/${AppMutation.setInteracted}`, true, { root: true })
      commit(DeviceUiMutation.setLoading, true)
      const isEcocoachEmployee = rootGetters[`app/${AppGetter.isEcocoachEmployee}`]
      const appDisplayLevels = [
        AppDisplayLevels.DISPLAYED_ON_FIRST_LEVEL,
        AppDisplayLevels.DISPLAYED_ON_SECOND_LEVEL,
        AppDisplayLevels.DISPLAYED_ON_THIRD_LEVEL,
      ]      
      const filter = (control: ControlModelV2) => {
        const deviceFilter = (c: ControlModelV2) => c.deviceId === deviceId
        const ecocoachFilter = (c: ControlModelV2) => isEcocoachEmployee || !c?.attributes?.ecocoachOnly
        const appDisplayLevelFilter = (c: ControlModelV2) => appDisplayLevels.includes(appDisplayLevel(c))
        return deviceFilter(control) && appDisplayLevelFilter(control) && ecocoachFilter(control)
      }
      await dispatch(`plcOperation/${PlcOperationAction.setControlsFilter}`, filter, { root: true })
      await delay(DelayAfterStartNotifiationsInMs)
    } finally {
      commit(`app/${AppMutation.setInteracted}`, false, { root: true })
      commit(DeviceUiMutation.setLoading, false)
    }
  },
  async [DeviceUiAction.setControlsFilterForScene]({ commit, rootGetters, rootState }): Promise<void> {
    try {
      commit(`app/${AppMutation.setInteracted}`, true, { root: true })
      commit(DeviceUiMutation.setLoading, true)
      const isEcocoachEmployee = rootGetters[`app/${AppGetter.isEcocoachEmployee}`]
      const appDisplayLevels = [
        AppDisplayLevels.DISPLAYED_ON_FIRST_LEVEL,
        AppDisplayLevels.DISPLAYED_ON_SECOND_LEVEL,
        AppDisplayLevels.DISPLAYED_ON_THIRD_LEVEL,
      ]      
      const sceneControlTypes = [
        ControlTypeV2.Toggle,
        ControlTypeV2.NumericInput,
        ControlTypeV2.EnumInput,
        ControlTypeV2.ConsumptionProcessToggle,
      ]
      const filter = (control: ControlModelV2) => {
        const ecocoachFilter = (c: ControlModelV2) => isEcocoachEmployee || !c?.attributes?.ecocoachOnly
        const appDisplayLevelFilter = (c: ControlModelV2) => appDisplayLevels.includes(appDisplayLevel(c))
        const typeFilter = (c: ControlModelV2) => sceneControlTypes.includes(c.type)
        const isDynamicMeterConsumptionProcessToggle = (c: ControlModelV2) => c.type === ControlTypeV2.ConsumptionProcessToggle &&
          !!rootState.plcOperation.devicesLookup.get(c.deviceId)?.consumptionValidationEnabled
        return typeFilter(control) && appDisplayLevelFilter(control) && ecocoachFilter(control) && !isDynamicMeterConsumptionProcessToggle(control)
      }
      // don't start notifications, only set the filter
      await commit(`plcOperation/${PlcOperationMutation.setControlsFilter}`, filter, { root: true })
      await delay(DelayAfterStartNotifiationsInMs)
    } finally {
      commit(`app/${AppMutation.setInteracted}`, false, { root: true })
      commit(DeviceUiMutation.setLoading, false)
    }
  },   
  async [DeviceUiAction.stopControlUpdates]({ commit, dispatch }): Promise<void> {
    try {
      commit(`app/${AppMutation.setInteracted}`, true, { root: true })
      await dispatch(`plcOperation/${PlcOperationAction.setControlsFilter}`, () => false, { root: true })
    } finally {
      commit(`app/${AppMutation.setInteracted}`, false, { root: true })
    }
  },  
  async [DeviceUiAction.createFavorite]({ commit, dispatch }, deviceId: string): Promise<void> {
    try {
      commit(`app/${AppMutation.setInteracted}`, true, { root: true })
      await dispatch(`plcOperation/${PlcOperationAction.createFavorite}`, deviceId, { root: true } )
    } finally {
      commit(`app/${AppMutation.setInteracted}`, false, { root: true })
    }
  },
  async [DeviceUiAction.deleteFavorite]({ commit, dispatch }, deviceId: string): Promise<void> {
    try {
      commit(`app/${AppMutation.setInteracted}`, true, { root: true })
      await dispatch(`plcOperation/${PlcOperationAction.deleteFavorite}`, deviceId, { root: true } )
    } finally {
      commit(`app/${AppMutation.setInteracted}`, false, { root: true })
    }
  },
}
