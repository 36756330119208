<template>
  <div class="app-view">
    <app-header class="app-view-header">
      <template v-slot:actions>
        <slot name="actions"/>
      </template>
    </app-header>
    <div id="app-view-content" :class="{ 'extend-side-padding': hasIosSafeArea}" :style="{'margin-top': this.topMargin+'px'}">
      <div class="loading" v-if="loading">
        <base-spinner/>
      </div>
      <slot v-else/>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import AppHeader from './AppHeader.vue'
import EnvironmentService from '../../../eco-domain-store-modules/src/services/environment.service'

@Component({
  components: {
    AppHeader,
  },
})
export default class AppView extends Vue {
  @Prop({type: Number, default: 10 }) public topMargin: number
  @Prop({type: Boolean, default: false }) public loading: boolean

  private get hasIosSafeArea() {
    return EnvironmentService.hasIosSafeArea
  }
}
</script>

<style lang="scss" scoped>
.app-view {
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1 0 0;
  overflow: auto;
  .app-view-header {
    flex: 0;
    min-height: 54px;
    height: 54px;
    border-bottom: 1px solid #000;
  }
  #app-view-content {
    flex: 1;
    margin-top: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; 
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    .loading {
      min-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media screen and (orientation: landscape) {
    .extend-side-padding {
      padding-left: 44px;
      padding-right: 44px;
    }
  }
  #app-view-content::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
}
</style>