<template>
  <div class="scene-control"
    :eco-control-id="control.id"
    :eco-name-resource-id="control.nameResourceId"
    :eco-app-display-level="control.appDisplayLevel"
    :eco-sort-order="control.sortOrder"
  >
    <control-component-v-2
      :context="contextV2"
      :control="controlViewModelV2"
      :potential-descendent-controls="[]"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import {
  ControlModelV2,
  SceneControlCommandModel,
} from '../../../../eco-domain-store-modules/src/plcOperation/models'


import { ControlCommand, ControlContextV2, ControlViewModelV2 } from '../../../../shared-components/src/components/deviceControls/v2/models'

import { SceneDetailsViewModel } from '../../store/modules/sceneUi/models'
import { stateForSceneControl } from '../../store/modules/sceneUi/helpers'

const SceneUi = namespace('sceneUi')
const Resource = namespace('resource')

@Component({
  components: {
    ControlComponentV2: () => import('@ecocoach/shared-components/src/components/deviceControls/ControlComponentV2.vue'),
  },
})
export default class SceneControl extends Vue {
  @Prop() public control: ControlModelV2
  @Prop() public scene: SceneDetailsViewModel
  @Resource.Getter public dictionary
  @SceneUi.Action public updateSceneControlCommand: (sceneControlCommand: SceneControlCommandModel) => Promise<void>

  public get controlViewModelV2() {
    const controlV2 = this.control as ControlModelV2
    const sceneControlCommands = this.scene.controlCommands.filter(c => c.controlId === this.control.id)
    return {
      id: controlV2.id,
      type: controlV2.type,
      attributes: controlV2.attributes,
      state: stateForSceneControl(controlV2, sceneControlCommands),
      pendingState: {},
    } as ControlViewModelV2
  }

  public get contextV2(): ControlContextV2 {
    return {
      executeCommand: (controlId: string, command: ControlCommand) => {
        this.updateSceneControlCommand({
          controlId,
          command: command.command,
          params: command.params,
        })
      },
      resolveStringResource: (resourceKey: string): string => {
        return this.dictionary(resourceKey)
      },
    } as ControlContextV2
  }
}
</script>
<style lang='scss' scoped>
.scene-control {
  margin: 5px 0px;
}
</style>
