import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { SceneUiState, SceneUiAction, SceneUiMutation } from './types'
import { PlcOperationAction } from '@ecocoach/domain-store-modules/src/plcOperation/types'
import { SceneControlCommandModel, SceneModel, SceneTimeScheduleModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { delay } from '@ecocoach/domain-store-modules/src/utils'
import { ScheduleType, mondayToFriday, daily } from './models'
import moment from 'moment'
import { getSchedule, getScheduleTriggerDateTimeUtc, newSchedule, sceneControlCommandsForLastValue, scheduleValid } from './helpers'
import { AppMutation } from '../app/types'

const TIMER_INTERVAL = 10000 // 10s
let timerHandle: any | null = null

export const actions: ActionTree<SceneUiState, RootState> = {
  async [SceneUiAction.loadScene]({ commit, rootState }, sceneId?: string): Promise<void> {
    if (sceneId) {
      const scene = rootState.plcOperation.scenes.find(s => s.id === sceneId)!
      commit(SceneUiMutation.setScene, scene)
    } else {
      commit(SceneUiMutation.resetScene, rootState.app.selectedProjectId)
    }
  },
  async [SceneUiAction.createScene]({ commit, dispatch, state }): Promise<void> {
    try {
      commit(`app/${AppMutation.setInteracted}`, true, { root: true })
      await dispatch(`plcOperation/${PlcOperationAction.createScene}`, {
        ...state.scene,
        timeSchedules: [],
      } as SceneModel, { root: true })
    } finally {
      commit(`app/${AppMutation.setInteracted}`, false, { root: true })
    }
  },
  async [SceneUiAction.saveScene]({ commit, dispatch, state }): Promise<void> {
    try {
      commit(`app/${AppMutation.setInteracted}`, true, { root: true })
      await dispatch(`plcOperation/${PlcOperationAction.updateScene}`, state.scene, { root: true })
    } finally {
      commit(`app/${AppMutation.setInteracted}`, false, { root: true })
    }
  },
  async [SceneUiAction.deleteScene]({ commit, dispatch, state }): Promise<void> {
    try {
      commit(`app/${AppMutation.setInteracted}`, true, { root: true })
      await dispatch(`plcOperation/${PlcOperationAction.deleteScene}`, state.scene.id, { root: true })
    } finally {
      commit(`app/${AppMutation.setInteracted}`, false, { root: true })
    }
  },
  async [SceneUiAction.addDeviceToScene]({ commit, getters }, deviceId: string): Promise<void> {
    getters.controlsOfDevice(deviceId).forEach(c => {
      const sceneControlCommands = sceneControlCommandsForLastValue(c)
      commit(SceneUiMutation.upsertSceneControlCommands, sceneControlCommands)
    })
  },
  async [SceneUiAction.removeDevicefromScene]({ commit, getters }, deviceId: string): Promise<void> {
    getters.controlsOfDevice(deviceId).forEach(c => {
      commit(SceneUiMutation.removeSceneControlCommands, c.id)
    })
  },
  async [SceneUiAction.updateSceneControlCommand]({ commit }, sceneControlCommand: SceneControlCommandModel): Promise<void> {
    commit(SceneUiMutation.upsertSceneControlCommands, [sceneControlCommand])
  },
  async [SceneUiAction.activateScene]({ commit, dispatch }, sceneId: string): Promise<void> {
    try {
      commit(`app/${AppMutation.setInteracted}`, true, { root: true })
      commit(SceneUiMutation.setSceneActivating, sceneId)
      dispatch(`plcOperation/${PlcOperationAction.activateScene}`, sceneId, { root: true })
      await delay(1000)
    } finally {
      commit(SceneUiMutation.resetSceneActivating, sceneId)
      commit(`app/${AppMutation.setInteracted}`, false, { root: true })
    }
  },
  async [SceneUiAction.loadSchedule]({ commit, state, rootState }, sceneId: string): Promise<void> {
    const scene = rootState.plcOperation.scenes.find(s => s.id === sceneId)!
    const schedule = getSchedule(scene)
    const validatedSchedule = scheduleValid(schedule, state.dateTimeUtcNow) ? schedule : newSchedule(schedule?.id)
    commit(SceneUiMutation.setScene, scene)
    commit(SceneUiMutation.setSchedule, validatedSchedule)
  },
  async [SceneUiAction.createSchedule]({ commit, dispatch, state }): Promise<void> {
    try {
      commit(`app/${AppMutation.setInteracted}`, true, { root: true })
      await dispatch(`plcOperation/${PlcOperationAction.createSceneSchedule}`, {
        sceneId: state.scene.id,
        schedule: state.scene.timeSchedules[0],
      }, { root: true })
    } finally {
      commit(`app/${AppMutation.setInteracted}`, false, { root: true })
    }
  },
  async [SceneUiAction.saveSchedule]({ commit, dispatch, state }): Promise<void> {
    try {
      commit(`app/${AppMutation.setInteracted}`, true, { root: true })
      await dispatch(`plcOperation/${PlcOperationAction.updateSceneSchedule}`, {
        sceneId: state.scene.id,
        schedule: state.scene.timeSchedules[0],
      }, { root: true })
    } finally {
      commit(`app/${AppMutation.setInteracted}`, false, { root: true })
    }
  },
  async [SceneUiAction.deleteSchedule]({ commit, dispatch, state }): Promise<void> {
    try {
      commit(`app/${AppMutation.setInteracted}`, true, { root: true })
      await dispatch(`plcOperation/${PlcOperationAction.deleteSceneSchedule}`, {
        sceneId: state.scene.id,
        scheduleId: state.scene.timeSchedules[0].id,
      }, { root: true })
    } finally {
      commit(`app/${AppMutation.setInteracted}`, false, { root: true })
    }
  },
  async [SceneUiAction.setScheduleType]({ commit }, type: ScheduleType): Promise<void> {
    commit(SceneUiMutation.setScheduleIsRecurring, type !== ScheduleType.oneTimePlay)
    if (type === ScheduleType.daily) {
      commit(SceneUiMutation.setScheduleDays, daily)
    } else if (type === ScheduleType.mondayToFriday) {
      commit(SceneUiMutation.setScheduleDays, mondayToFriday)
    } else if (type === ScheduleType.oneTimePlay) {
      commit(SceneUiMutation.setScheduleDays, [])
    } else if (type === ScheduleType.userdefined) {
      commit(SceneUiMutation.setScheduleDays, [])
    }
  },
  async [SceneUiAction.setScheduleDate]({ commit, state }, localDate: string): Promise<void> {
    const existingDateTimeStringUtc = getScheduleTriggerDateTimeUtc(state.scene)
    const [year, month, day] = localDate.split('-')
    const modifiedDateTimeUtcString = moment.utc(existingDateTimeStringUtc).local()
      .year(Number(year))
      .month(Number(month) - 1) // moment.js months are 0-based
      .date(Number(day))
      .utc().toISOString()
    commit(SceneUiMutation.setScheduleDateTime, modifiedDateTimeUtcString)
  },
  async [SceneUiAction.setScheduleTime]({ commit, state }, localTime: string): Promise<void> {
    const existingDateTimeStringUtc = getScheduleTriggerDateTimeUtc(state.scene)
    const [hour, minute] = localTime.split(':')
    const modifiedDateTimeUtcString = moment.utc(existingDateTimeStringUtc).local()
      .hour(Number(hour))
      .minute(Number(minute))
      .utc().toISOString()
    commit(SceneUiMutation.setScheduleDateTime, modifiedDateTimeUtcString)
  },
  async [SceneUiAction.activateSchedule]({ commit, dispatch, rootState }, sceneId: string): Promise<void> {
    try {
      commit(`app/${AppMutation.setInteracted}`, true, { root: true })
      const scene = rootState.plcOperation.scenes.find(s => s.id === sceneId)!
      await dispatch(`plcOperation/${PlcOperationAction.updateSceneSchedule}`, {
        sceneId: scene.id,
        schedule: {
          ...scene.timeSchedules[0],
          isEnabled: true,
        } as SceneTimeScheduleModel,
      }, { root: true })
    } finally {
      commit(`app/${AppMutation.setInteracted}`, false, { root: true })
    }
  },
  async [SceneUiAction.deactivateSchedule]({ commit, dispatch, rootState }, sceneId: string): Promise<void> {
    try {
      commit(`app/${AppMutation.setInteracted}`, true, { root: true })
      const scene = rootState.plcOperation.scenes.find(s => s.id === sceneId)!
      await dispatch(`plcOperation/${PlcOperationAction.updateSceneSchedule}`, {
        sceneId: scene.id,
        schedule: {
          ...scene.timeSchedules[0],
          isEnabled: false,
        } as SceneTimeScheduleModel,
      }, { root: true })
    } finally {
      commit(`app/${AppMutation.setInteracted}`, false, { root: true })
    }
  },
  async [SceneUiAction.startTimer]({ commit }): Promise<void> {
    timerHandle = setInterval(() => commit(SceneUiMutation.setDateTimeUtcNow, moment.utc().toISOString()), TIMER_INTERVAL)
  },
  async [SceneUiAction.stopTimer](): Promise<void> {
    if (timerHandle) {
      clearInterval(timerHandle)
      timerHandle = null
    }
  },
}
