<template>
  <app-view>
    <icon-header v-if="hasAlarms"
      :title="'navigation.alarms' | translate"
      material-icon="alarm"
    />
    <div class="alarms">
      <v-layout
        class="alarm-row"
        v-for="customPlcAlarm in alarms"
        :key="customPlcAlarm.alarmInstanceId"
        column
      >
        <v-flex @click="navigateToDevice(customPlcAlarm.deviceId)">
          <v-layout row align-center justify-space-between>
            <v-flex shrink>
              <icon-with-badge :material-icon="icon(customPlcAlarm)" :badge="badge(customPlcAlarm)" :badge-color="badgeColor(customPlcAlarm)" />
            </v-flex>
            <v-flex grow class="alarm-name truncate">
              {{ customPlcAlarm.displayName }}
            </v-flex>
            <v-flex shrink class="alarm-spacer" />
            <v-flex grow class="alarm-message truncate">
              {{ customPlcAlarm.displayMessage }}
            </v-flex>
          </v-layout>
          <v-flex class="alarm-time">
            {{ timeStampDisplayName(customPlcAlarm.openedTimestamp) }}
            <span v-if="customPlcAlarm.isOpen === false">
              &nbsp;-&nbsp;
              {{ timeStampDisplayName(customPlcAlarm.closedTimestamp) }}
            </span>
          </v-flex>
        </v-flex>
      </v-layout>
    </div>
    <div class="alarm-refresh">
      <icon-button v-if="hasAlarms"
        @buttonClicked="loadMoreAlarmLogs"
        type="materialIcon"
        materialIconName="refresh"
        :height="20"
        :width="20"
        :enabled="!isInteracted"
      />
    </div>
    <div v-if="alarms.length === 0">
      {{ 'customPlcAlarms.no_items' | translate }}
    </div>
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import IconWithBadge from './shared/IconWithBadge.vue'
import ListNavigationItem from './shared/ListNavigationItem.vue'
import IconButtonWithBadge from './shared/IconButtonWithBadge.vue'
import { CustomPlcAlarmViewModel } from '../store/modules/alarmLogUi/models'
import { formatAlarmHistoryTimeStamp } from '../store/modules/alarmLogUi/helpers'
import StackRouter from '../router'
import { AlarmLevel } from '../../../eco-domain-store-modules/src/common'

const AlarmLogUi = namespace('alarmLogUi')

@Component({
  components: {
    IconButtonWithBadge,
    AppView,
    IconHeader,
    IconWithBadge,
    ListNavigationItem,
  },
})
export default class AlarmsOverview extends Vue {
  @AlarmLogUi.Getter public isInteracted: boolean
  @AlarmLogUi.Getter public alarms: CustomPlcAlarmViewModel[]
  @AlarmLogUi.Action public openAlarmLogForProject: () => Promise<void>
  @AlarmLogUi.Action public loadMoreAlarmLogForProject: () => Promise<void>

  public mounted() {
    this.openAlarmLogForProject()
  }

  public get hasAlarms() {
    return !!this.alarms.length
  }

  public loadMoreAlarmLogs() {
    this.loadMoreAlarmLogForProject()
  }

  public icon(alarm: CustomPlcAlarmViewModel) {
    return alarm.isOpen ? 'notifications_active' : 'notifications_off'
  }

  public badge(alarm: CustomPlcAlarmViewModel) {
    if (!alarm.isOpen) {
      return ''
    }
    switch (alarm.level) {
    case AlarmLevel.Critical: return '!'
    case AlarmLevel.Information: return 'i'
    default: return '!'
    }
  }

  public badgeColor(alarm: CustomPlcAlarmViewModel) {
    switch (alarm.level) {
    case AlarmLevel.Critical: return 'red'
    case AlarmLevel.Information: return 'blue'
    default: return 'red'
    }
  }

  public timeStampDisplayName(timeStamp) {
    return formatAlarmHistoryTimeStamp(timeStamp)
  }

  public navigateToDevice(deviceId: string) {
    if (deviceId) {
      Vue.$log.info('Navigating to alarmdevice', deviceId)
      StackRouter.navigate({ name: 'alarmdevice', params: { deviceId } })
    }
  }
}
</script>

<style lang="scss" scoped>
.alarms {
  .alarm-row {
    padding: 5px 15px;
    border-bottom: 1px solid #2d3442;

    .alarm-name {
      text-align: left;
      font-size: 16px;
      white-space: nowrap;
      overflow: auto;
      width: 30%;
    }

    .alarm-name::-webkit-scrollbar {
      display: none;
    }

    .alarm-message {
      text-align: left;
      white-space: nowrap;
      overflow: auto;
      width: 70%;
    }

    .alarm-message::-webkit-scrollbar {
      display: none;
    }

    .alarm-time {
      text-align: left;
      white-space: nowrap;
      overflow: auto;
      float: left;
    }

    .alarm-time::-webkit-scrollbar {
      display: none;
    }

    .alarm-spacer {
      min-width: 10px;
    }

    .truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
