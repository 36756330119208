<template>
  <modal-dialog 
    :show="show"
    :minimal="true"
  >
    <v-layout column justify-center align-center>
      <form-field v-if="showNodeSelection" :text="'charts.settings.emsNode' | translate" class="field">
        <base-single-select
          :options="nodeOptions"
          option-value="id"
          option-label="label"
          :selectedValue="selectedNodeId"
          @selected="selectNodeId"
        />
      </form-field>
      <checkbox-with-label v-if="showScaleSelection"
        :value="scaleSelected"
        :text="'charts.settings.scaleToAuthorized' | translate"
        @checked="selectScale(!$event)"
      />
      <div>
        <base-text-button @buttonClicked="onOk">{{ 'common.button.close' | translate }}</base-text-button>
      </div>
    </v-layout>      
  </modal-dialog>
</template>

<script lang="ts">
import { DropdownOption } from '@ecocoach/shared-components/src'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const ChartUi = namespace('chartUi')

@Component({
  components: {
  },
})
export default class ChartSettingsDialog extends Vue {
  @Prop() public show: boolean
  @ChartUi.Getter public showNodeSelection:  boolean
  @ChartUi.Getter public nodeOptions:  DropdownOption[]
  @ChartUi.Getter public selectedNodeId: string
  @ChartUi.Getter public showScaleSelection:  boolean
  @ChartUi.Getter public scaleSelected: boolean
  @ChartUi.Action public selectNodeId: (nodeId: string) => Promise<void>
  @ChartUi.Action public selectScale: (scale: boolean) => Promise<void>

  initiallySelectedNodeId = ''
  initiallyScaleSelected = false

  @Watch('show')
  public async onShow() {
    this.initiallySelectedNodeId = this.selectedNodeId
    this.initiallyScaleSelected = this.scaleSelected
  }


  @Emit('closed')
  public onOk() {
    const changed = this.initiallySelectedNodeId !== this.selectedNodeId || this.initiallyScaleSelected !== this.scaleSelected
    return changed
  }
}
</script>
<style lang='scss' scoped>
.v-btn {
  border: 1px solid white;
}
</style>
    
