<template>
  <app-view>
    <template v-slot:actions>
      <icon-button
        @buttonClicked="onAddScene"
        type="materialIcon"
        materialIconName="add"
        :height="20"
        :width="20"
        :enabled="!isInteracted"
      />
    </template>
    <div class="scenes">
      <v-layout class="scene-row" v-for="scene in scenes" :key="scene.id" column>
        <v-flex>
          <v-layout row align-center justify-space-between>
            <v-flex shrink class="scene-icon" @click="navigateToScene(scene)" >
              <icon-with-gradient v-ripple
                :icon="scene.icon"
                :gradient="scene.gradient"
              />
            </v-flex>
            <v-flex shrink class="scene-spacer"/>
            <v-flex shrink class="scene-name" @click="navigateToScene(scene)" >
              {{scene.name}}
            </v-flex>
            <v-flex grow class="scene-spacer"/>
            <v-flex shrink class="scene-button scene-schedule" 
              :class="{ 'active': scene.scheduleActive, 'disabled': !scene.scheduleValid }"
            >
              <icon-button
                @buttonClicked="onToggleSchedule(scene)"
                v-long-click="() => onNavigateToSchedule(scene)"
                type="materialIcon"
                materialIconName="schedule"
                :height="20"
                :width="20"
                :enabled="!isInteracted"
              />
            </v-flex>
            <v-flex shrink class="scene-spacer"/>
            <v-flex shrink class="scene-button scene-activate" :class="{ 'active': scene.isActivating }" >
              <icon-button v-if="!scene.isActivating"
                @buttonClicked="onActivateScene(scene)"
                type="materialIcon"
                materialIconName="play_arrow"
                :height="20"
                :width="20"
                :enabled="!isInteracted"
              />
              <base-spinner v-else/>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="schedule-text" 
          :class="{ disabled: !scene.scheduleValid || !scene.scheduleActive }"
          @click="navigateToScene(scene)"
        >
          {{scene.scheduleText}}
        </v-flex>
      </v-layout>
    </div>
    <div v-if="scenes.length === 0">
      {{ 'scenes.no_items' | translate }}
    </div>
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import IconWithGradient from './shared/IconWithGradient.vue'
import StackRouter from '../router'
import { SceneViewModel } from '../store/modules/sceneUi/models'

const App = namespace('app')
const SceneUi = namespace('sceneUi')
const Resource = namespace('resource')
const DeviceUi = namespace('deviceUi')

@Component({
  components: {
    AppView,
    IconWithGradient,
  },
})
export default class Scenes extends Vue {
  @App.Getter public isInteracted: boolean
  @SceneUi.Getter public scenes: SceneViewModel[]
  @SceneUi.Action public activateScene: (id: string) => Promise<void>
  @SceneUi.Action public activateSchedule: (id: string) => Promise<void>
  @SceneUi.Action public deactivateSchedule: (id: string) => Promise<void>
  @SceneUi.Action public startTimer: () => Promise<void>
  @SceneUi.Action public stopTimer: () => Promise<void>
  @App.Action public toastInfo: (message: string) => Promise<void>
  @Resource.Getter public dictionary
  @DeviceUi.Action public stopControlUpdates: () => Promise<void>

  public beforeMount() {
    this.startTimer()
  }

  public mounted() {
    this.stopControlUpdates()
  }

  public beforeDestroy() {
    this.stopTimer()
  }

  public onAddScene() {
    Vue.$log.info('Navigating to add scene')
    StackRouter.navigate({ name: 'addscene' })
  }

  public onToggleSchedule(scene: SceneViewModel) {
    if (scene.scheduleValid) {
      if (!scene.scheduleActive) {
        this.activateSchedule(scene.id)
      } else {
        this.deactivateSchedule(scene.id)
      }
    } else {
      this.toastInfo(this.dictionary('scenes.scene.expiredMessage'))
    }
  }

  public onActivateScene(scene: SceneViewModel) {
    this.activateScene(scene.id)
  }

  public navigateToScene(scene: SceneViewModel) {
    Vue.$log.info('Navigating to scene', scene.id)
    StackRouter.navigate({ name: 'scene', params: { sceneId: scene.id } })
  }

  public onNavigateToSchedule(scene: SceneViewModel) {
    Vue.$log.info('Navigating to schedule of scene', scene.id)
    StackRouter.navigate({ name: 'schedule', params: { sceneId: scene.id } })
  }
}
</script>
<style lang='scss' scoped>
.scenes {
  .scene-row {
    padding: 5px 15px;
    border-bottom: 1px solid #2d3442;
    .scene-name {
      text-align: left;
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;      
    }
    .scene-button {
      width: 51px;
      height: 31px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .scene-schedule {
      border: 1px solid lightgray;
      &.active {
        border: 2px solid #fa0f36;
        box-shadow: 0px 0px 11px 0px #fa0f36;
      }
      &.disabled {
        border: 1px solid #7a8b9a;
        box-shadow: 0px 0px 11px 0px #7a8b9a;
        * {
          opacity: 0.5;
        }
      }
    }
    .scene-activate {
      border: 1px solid lightgray;
      &.active {
        border: 1px solid #fa0f36;
        box-shadow: 0px 0px 11px 0px #fa0f36;
      }
    }
    .scene-spacer {
      min-width: 10px;
    }
    .schedule-text {
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &.disabled {
        color: #7a8b9a;
      }
    }
  }
}

</style>
<style lang="css">
.scenes .base-spinner > div {
  max-height: 10px;
  max-width: 10px;
  background-color: #fa0f36;
}
</style>
