export interface AlarmUiState {
  drawerVisible: boolean
}

export enum AlarmUiMutation {
  setDrawerVisible = 'setDrawerVisible',
}

export enum AlarmUiGetter {
  alarms = 'alarms',
  isDrawerVisible = 'isDrawerVisible',
  isServiceDisturbed = 'isServiceDisturbed',
}
