<template>
  <v-dialog
    :value="show"
    max-width="321"
    @input="onInput"
  >
    <v-layout column class="time-period-pickers">
      <v-flex shrink>
        <tab-headers 
          :tabs="tabs"
          :selected-tab="selectedPeriod"
          @changed="onPeriodSelected"
        />
      </v-flex>
      <v-flex grow class="tab">
        <div :class="{ 'hidden': selectedPeriod !== 'Day' }">
          <v-date-picker no-title scrollable
            :value="selectedDay"
            :max="today"
            :first-day-of-week="1"
            :locale="language"
            @input="onDaySelected"
          />
        </div>
        <div :class="{ 'hidden': selectedPeriod !== 'Week' }">
          <v-date-picker no-title scrollable multiple show-week
            :value="selectedWeek"
            :max="today"
            :first-day-of-week="1"
            :locale="language"
            @input="onWeekSelected($event[$event.length-1])"
          />
        </div>
        <div :class="{ 'hidden': selectedPeriod !== 'Month' }">
          <v-date-picker no-title scrollable
            type="month"
            :value="selectedMonth"
            :max="today"
            :locale="language"
            @input="onMonthSelected($event+'-01')"
          />
        </div>
        <div :class="{ 'hidden': selectedPeriod !== 'Year' }">
          <v-date-picker no-title scrollable reactive
            ref="yearPicker"
            :value="selectedYear"
            :max="today"
            :locale="language"
            @input="onYearSelected"
          />
        </div>
      </v-flex>
    </v-layout>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import TabHeaders from './TabHeaders.vue'
import moment from 'moment'
import { PeriodType } from '../../../../eco-domain-store-modules/src/chart/models'
import { getMomentPeriod } from '../../store/modules/chartUi/helpers'

const Resource = namespace('resource')

@Component({
  components: {
    TabHeaders,
  },
})
export default class TimePeriodPickerDialogNext extends Vue {
  @Prop() public show: boolean
  @Prop() public periods: PeriodType[]
  @Prop() public defaultPeriod: PeriodType
  @Prop() public defaultDate: string
  @Prop() public language: string
  @Resource.Getter public dictionary

  public selectedPeriod = PeriodType.Day
  public selectedDate = ''
  public yearPickerDate = ''
  public yearPickerActivePicker = ''

  @Watch('show')
  public onShow(value: boolean) {
    if (value) {
      this.selectedPeriod = this.defaultPeriod
      this.selectedDate = this.defaultDate
    }
  }

  @Emit('selected')
  public emitSelection() {
    return {
      period: this.selectedPeriod,
      date: this.selectedDate,
    }
  }

  @Emit('close')
  public onInput() {
    return
  }

  @Watch('defaultDate')
  public defaultDateChanged(value: string) {
    this.selectedDate = value
  }

  public get tabs() {
    return [
      { id: PeriodType.Day, text: this.dictionary('energy.calendar.type.day'), disabled: !this.periods.includes(PeriodType.Day) },
      { id: PeriodType.Week, text: this.dictionary('energy.calendar.type.week'), disabled: !this.periods.includes(PeriodType.Week) },
      { id: PeriodType.Month, text: this.dictionary('energy.calendar.type.month'), disabled: !this.periods.includes(PeriodType.Month) },
      { id: PeriodType.Year, text: this.dictionary('energy.calendar.type.year'), disabled: !this.periods.includes(PeriodType.Year) },
    ]
  }

  public get selectedDay() {
    return this.selectedDate
  }

  public get today() {
    return moment().format('YYYY-MM-DD')
  }

  public get selectedWeek() {
    if (!this.selectedDate) {
      return []
    }
    const format = (dateTime: moment.Moment) => dateTime.format('YYYY-MM-DD')
    const beginOfWeek = moment(this.selectedDate).startOf(getMomentPeriod(PeriodType.Week))
    return [
      format(beginOfWeek),
      format(beginOfWeek.add(1, 'day')),
      format(beginOfWeek.add(1, 'day')),
      format(beginOfWeek.add(1, 'day')),
      format(beginOfWeek.add(1, 'day')),
      format(beginOfWeek.add(1, 'day')),
      format(beginOfWeek.add(1, 'day')),
    ]
  }

  public get selectedMonth() {
    return this.selectedDate.slice(0, -3)
  }

  public get selectedYear() {
    return this.selectedDate
  }

  public onPeriodSelected(value: PeriodType) {
    this.selectedPeriod = value
    this.forceYearPickerYearSelection()
  }

  public onDaySelected(value: string) {
    this.selectedDate = value
    this.emitSelection()
  }

  public onWeekSelected(value: string) {
    const beginOfWeek = moment(value).startOf(getMomentPeriod(PeriodType.Week)).format('YYYY-MM-DD')
    this.selectedDate = beginOfWeek
    this.emitSelection()
  }

  public onMonthSelected(value: string) {
    this.selectedDate = value
    this.emitSelection()
  }

  public onYearSelected(value: string) {
    this.selectedDate = value
    this.emitSelection()
    this.forceYearPickerYearSelection()
  }

  private forceYearPickerYearSelection() {
    this.$nextTick(() => {
      if (this.$refs.yearPicker) {
        (this.$refs.yearPicker as any).activePicker = 'YEAR'
      }
    })
  }
}
</script>
<style lang='scss' scoped>
.time-period-pickers {
  height: 321px;
  .tab {
    background: linear-gradient(#1f3c5e,#1c385a);
    .hidden {
      display: none;
    }
  }
}
</style>
<style lang='css'>
.time-period-pickers .v-picker, .time-period-pickers .v-picker .v-picker__body {
  background-color: transparent !important
}
.time-period-pickers .accent {
    background-color: #fa0f36 !important;
    border-color: #fa0f36 !important;
}
.time-period-pickers .v-date-picker-years .primary--text {
    color: #fa0f36 !important;
}

</style>


