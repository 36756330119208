<template>
  <app-view :loading="loading">
    <icon-header class="room-name"
      :title="room && room.name"
      :icon="room && room.icon"
    />
    <v-layout row wrap align-start class="device-list">
      <device-box v-for="device in devices" :key="device.id">
        <div class="device-header" @click="navigateToDevice(device.id)" v-ripple>
          <svg-icon class="device-icon" :icon="device.icon" :width="35" :height="35"/>
          <div class="device-name">{{ device.name }}</div>
          <base-material-icon iconName="keyboard_arrow_right" :height="20" />
        </div>
        <div class="device-controls">
          <device-control v-for="control in roomControlsOfDevice(device.id)"
            :key="control.id"
            :control="control"
          />
        </div>
      </device-box>
    </v-layout>
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import DeviceBox from './shared/DeviceBox.vue'
import DeviceControl from './shared/DeviceControl.vue'
import SvgIcon from './shared/SvgIcon.vue'
import IconHeader from './shared/IconHeader.vue'
import StackRouter from '../router'
import { DeviceModel, RoomModel, ControlModelV2 } from '../../../eco-domain-store-modules/src/plcOperation/models'

const DeviceUi = namespace('deviceUi')

@Component({
  components: {
    AppView,
    DeviceBox,
    DeviceControl,
    IconHeader,
    SvgIcon,
  },
})
export default class Room extends Vue {
  @DeviceUi.Getter public loading: boolean
  @DeviceUi.Getter public roomById: (roomId: string) => RoomModel
  @DeviceUi.Getter public roomDevicesOfRoom: (roomId: string) => DeviceModel[]
  @DeviceUi.Getter public roomControlsOfDevice: (deviceId: string) => ControlModelV2[]
  @DeviceUi.Action public startControlUpdatesForRoom: (roomId: string) => Promise<void>

  public async mounted() {
    if (!this.room) {
      Vue.$log.warn('room not found', this.roomId)
      await StackRouter.navigate({ name: 'notfound', params: { id: this.roomId } })
      return
    }
    this.startControlUpdatesForRoom(this.roomId)
  }

  public get roomId(): string {
    return this.$route.params.roomId
  }

  public get room(): RoomModel {
    return this.roomById(this.roomId)
  }

  public get devices() {
    return this.roomDevicesOfRoom(this.roomId)
  }

  public navigateToDevice(deviceId: string) {
    Vue.$log.info('Navigating to roomdevice', deviceId)
    StackRouter.navigate({ name: 'roomdevice', params: { deviceId } })
  }
}
</script>
<style lang='scss' scoped>
.room-name {
  margin: 0px 20px;
}
.device-list {
  flex: 0;
  .device-header {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    .device-icon {
      flex: 0;
      margin-right: 25px;
    }
    .device-name {
      flex: 1;
      font-size: 16px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 5px;
      max-width: 100%;
    }
  }
  .device-controls {
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
    margin-left: 60px;
  }
}
</style>