import { ChartDataModel, ChartDataSeriesModel, ChartType, IntervalType, PeriodType } from '@ecocoach/domain-store-modules/src/chart/models'
import convert from 'convert-units'
import { ColorString, DashStyleValue, GradientColorObject, SeriesOptionsType } from 'highcharts'
import Gradients from '@ecocoach/shared-components/src/components/deviceControls/v1/shared/gradients'
import moment from 'moment'
import { ChartContext } from './models'

const MIN_CHART_PLOT_AREA_HEIGHT = 380
const LEGEND_ITEM_HEIGHT = 18
const LEGEND_ITEM_WIDTH = 280
const LEGEND_MARGIN_WIDTH = 35

const MIN_Y_AXIS_RANGE_TO_POWER = 2 // kW
const MIN_Y_AXIS_RANGE_TO_ENERGY = 10 // kWh
const MIN_Y_AXIS_RANGE_TO_VOLUME = 0.5 // m³

export const getPeriodStartUtc = (dateUtc: string, period: PeriodType): moment.Moment => {
  const dateTimeUtc = dateUtc ? moment.utc(dateUtc) : moment.utc()
  return dateTimeUtc.local().startOf(getMomentPeriod(period)).utc()
}

export const getNextPeriodStartUtc = (dateUtc: string, period: PeriodType): moment.Moment => {
  const periodStartUtc = getPeriodStartUtc(dateUtc, period)
  const nextPeriodStart = periodStartUtc.local().endOf(getMomentPeriod(period)).add(1, 'ms').utc()
  return nextPeriodStart
}

export const getPreviousPeriodStartUtc = (dateUtc: string, period: PeriodType): moment.Moment => {
  const periodStartUtc = getPeriodStartUtc(dateUtc, period)
  const previousPeriodStart = periodStartUtc.local().subtract(1, 'ms').startOf(getMomentPeriod(period)).utc()
  return previousPeriodStart
}

export const getMomentPeriod = (period: PeriodType): moment.unitOfTime.StartOf => {
  switch (period) {
  case PeriodType.Day: return 'day'
  case PeriodType.Week: return 'isoWeek'
  case PeriodType.Month: return 'month'
  case PeriodType.Year: return 'year'
  default: throw Error(`unsupported period type: ${period}`)
  }
}

export const formatDay = (dateUtc: string) => {
  return moment(dateUtc).local().format('D.M.Y')
}

export const formatWeek = (dateUtc: string) => {
  const weekStart = moment(dateUtc).local()
  const weekEnd = moment(dateUtc).local().add(6, 'day')
  return `KW ${weekStart.format('W')}: ${weekStart.format('D.M.Y')} - ${weekEnd.format('D.M.Y')}`
}

export const formatMonth = (dateUtc: string) => {
  return moment(dateUtc).local().format('MMMM Y')
}

export const formatYear = (dateUtc: string) => {
  return moment(dateUtc).local().format('Y')
}

export const makeChartConfig = (data: ChartDataModel, context: ChartContext): Highcharts.Options => {
  return {
    height: '100vh',
    width: '100vw',
    chart: {
      type: data.type.toLowerCase(),
      backgroundColor: 'transparent',
    },
    title: undefined,
    legend: {
      itemStyle: {
        color: 'white',
        fontFamily: 'Titillium Web',
        fontSize: '14',
        fontWeight: '300',
      },
      itemHoverStyle: {
        color: 'white',
        fontWeight: 'bold',
      },
      itemHiddenStyle: {
        color: 'gray',
      },
      labelFormatter: getLegendFormatter(context.resolveStringResource),
      navigation: {
        enabled: false,
      },
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    xAxis: makeXAxis(data),
    yAxis: makeYAxis(data),
    plotOptions: {
      series: {
        shadow: true,
        borderColor: 'rgba(0,0,0,1)',
        animation: false,
        allowPointSelect: false,
        stickyTracking: false,
        dataLabels: {
          enabled: false,
        },
        marker: {
          symbol: 'circle',
          enabled: false,
        },
        states: {
          inactive: {
            opacity: 0.33,
          },
        },
        events: {
          legendItemClick(e) {
            if (!e.target.data.length) {
              // disable hide/show for key figures
              e.preventDefault()
              return
            }
            if (e.target.options.id) {
              // callback to make state persistent
              context.seriesVisibilityToggled(e.target.options.id)
            }
          },
        },
      },
      area: {
        stacking: data.stacking.toLowerCase(),
        lineWidth: 0,
      },
      column: {
        stacking: data.stacking.toLowerCase(),
        borderWidth: .2,
      },
    },
    series: makeSeries(data, context),
    tooltip: {
      formatter: getTooltipFormatter(data),
    },
    responsive: {
      rules: getResponsiveLegendRules(data),
    },
  } as Highcharts.Options
}

const makeXAxis = (data: ChartDataModel): Highcharts.XAxisOptions => {
  return {
    type: 'datetime',
    tickPixelInterval: 50,
    minTickInterval: 1000 * 60 * 60, // 1h
    tickColor: 'rgba(83, 87, 97, .75)',
    tickWidth: 1,
    tickLength: 8,
    gridLineWidth: data.type === ChartType.Column ? 0 : 1,
    gridLineColor: 'rgb(133,130,125)',
    lineColor: 'rgba(83, 87, 97, .75)',
    labels: {
      formatter: getXAxisLabelFormatter(data),
      y: 25,
      style: {
        color: 'white',
        fontStyle: 'normal',
        fontFamily: 'Titillium Web',
        fontSize: '12',
        fontWeight: '500',
      },
    },
  }
}

const makeYAxis = (data: ChartDataModel): Highcharts.YAxisOptions => {
  const unit = data.series[0]?.unit ?? ''
  return {
    className: 'highcharts-color-0',
    gridLineWidth: 1,
    gridLineColor: 'rgb(133,130,125)',
    tickColor: 'rgba(83, 87, 97, .75)',
    title: {text: ''},
    labels: {
      formatter: getYAxisLabelFormatter(data),
      style: {
        color: 'white',
        fontStyle: 'normal',
        fontFamily: 'Titillium Web',
        fontSize: '12',
        fontWeight: '300',
      },
    },
    min: unit === '%' ? 0 : undefined,
    max: unit === '%' ? 100 : undefined,
    softMin: 0,
    softMax: getYAxisSoftMax(unit),
    tickInterval: unit === '%' ? 10 : undefined,
  }
}

const getXAxisLabelFormatter = (data: ChartDataModel): any => {
  switch (data.period) {
  case PeriodType.Day:
    return function(this) {
      return moment(this.value).format('HH:mm')
    }
  case PeriodType.Week:
    if (data.interval === IntervalType.FifteenMinutes) {
      return function(this) {
        return moment(this.value).format('dd HH:mm')
      }
    } else {
      return function(this) {
        return moment(this.value).format('dd')
      }
    }
  case PeriodType.Month:
    return function(this) {
      return moment(this.value).format('D.MM')
    }
  case PeriodType.Year:
    return function(this) {
      return moment(this.value).format('MMM')
    }
  default: return ''
  }
}

const getYAxisLabelFormatter = (data: ChartDataModel): any => {
  const dataUnit = data.series[0]?.unit ?? ''
  return function(this) {
    return formatValueWithBestUnit(this.value, dataUnit, undefined, getAxisDataMax(this.axis))
  }
}

const getYAxisSoftMax = (unit: string): any => {
  switch (unit) {
  case 'kW': return MIN_Y_AXIS_RANGE_TO_POWER
  case 'kWh': return MIN_Y_AXIS_RANGE_TO_ENERGY
  case 'm³': return MIN_Y_AXIS_RANGE_TO_VOLUME
  default: return 1
  }
}

const makeSeries = (data: ChartDataModel, context: ChartContext): SeriesOptionsType[] => {
  const pointStart = getXAxisPointStart(data)
  const pointIntervalUnit = getXAxisPointIntervalUnit(data)
  const pointInterval = getXAxisPointInterval(data)
  return data.series
    .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
    .map(series => {
      const id = makeSeriesKey(series)
      return {
        id,
        name: series.name,
        data: series.data,
        type: data.type.toLowerCase(),
        stack: series.group,
        grouping: false,
        pointStart,
        pointIntervalUnit,
        pointInterval,
        color: makeSeriesColor(series),
        lineWidth: makeSeriesLineWith(series),
        dashStyle: makeSeriesDashStyle(series),
        // custom properties for formatters
        unit: series.unit,
        tag: series.tag,
        legendName: series.legend.name,
        legendValue: series.legend.value,
        legendUnit: series.legend.unit,
        visible: context.seriesVisible(id),
      } as SeriesOptionsType
    }).concat(data.keyFigures.map(keyFigure => {
      return {
        name: keyFigure.name,
        type: 'line',
        color: 'transparent',
        // custom properties for formatters
        legendName: keyFigure.legend.name,
        legendValue: keyFigure.legend.value,
        legendUnit: keyFigure.legend.unit,
      } as SeriesOptionsType
    }))
}

const getXAxisPointStart = (data: ChartDataModel): number => {
  switch (data.interval) {
  case IntervalType.FifteenMinutes:
    return Number(moment.utc(data.dateFrom).format('x'))
  default:
    return Number(moment.utc(data.dateFrom).add(12, 'h').startOf('d').format('x')) // round to nearest utc day to center column
  }
}

const getXAxisPointIntervalUnit = (data: ChartDataModel): string | undefined => {
  switch (data.interval) {
  case IntervalType.Daily: return 'day'
  case IntervalType.Monthly: return 'month'
  default: return undefined
  }
}

const getXAxisPointInterval = (data: ChartDataModel): number => {
  switch (data.interval) {
  case IntervalType.FifteenMinutes: return moment.duration({minutes: 15}).asMilliseconds()
  default: return 1
  }
}

const makeSeriesColor = (series: ChartDataSeriesModel): GradientColorObject | ColorString => {
  switch (series.tag) {
  case 'Mean':
    return Gradients.secondaryColor(series.style.colorGradient)
  case 'Min':
    return Gradients.secondaryColor('gradient-1') // red
  case 'Max':
    return Gradients.secondaryColor('gradient-11') // green
  default:
    return {
      linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
      stops: [
        [0, Gradients.stops(series.style.colorGradient)[0]],
        [1, Gradients.stops(series.style.colorGradient)[1]],
      ],
    }
  }
}

const makeSeriesLineWith = (series: ChartDataSeriesModel): number | undefined =>  {
  switch (series.tag) {
  case 'Mean':
    return 3
  case 'Min':
  case 'Max':
    return 1
  default:
    return undefined
  }
}

const makeSeriesDashStyle = (series: ChartDataSeriesModel): DashStyleValue =>  {
  switch (series.tag) {
  case 'Min':
    return 'Dash'
  case 'Max':
    return 'Dot'
  default:
    return 'Solid'
  }
}

const getTooltipFormatter = (data: ChartDataModel): any => {
  switch (data.period) {
  case PeriodType.Day:
    return function(this) {
      return `${formatValueWithBestUnit(this.y, this.series.options.unit, 1, getAxisDataMax(this.series.yAxis))} - ${moment(this.x).format('HH:mm')}`
    }
  case PeriodType.Week:
    if (data.interval === IntervalType.FifteenMinutes) {
      return function(this) {
        return `${formatValueWithBestUnit(this.y, this.series.options.unit, 1, getAxisDataMax(this.series.yAxis))} - ${moment(this.x).format('dd HH:mm')}`
      }
    } else {
      return function(this) {
        return `${formatValueWithBestUnit(this.y, this.series.options.unit, 1, getAxisDataMax(this.series.yAxis))} - ${moment(this.x).format('dd, D.MM.')}`
      }
    }
  case PeriodType.Month:
    return function(this) {
      return `${formatValueWithBestUnit(this.y, this.series.options.unit, 1, getAxisDataMax(this.series.yAxis))} - ${moment(this.x).format('D.MM.')}`
    }
  case PeriodType.Year:
    return function(this) {
      return `${formatValueWithBestUnit(this.y, this.series.options.unit, 1, getAxisDataMax(this.series.yAxis))} - ${moment(this.x).format('MMM YYYY')}`
    }
  default: return ''
  }
}

const getLegendFormatter = (resolveStringResource: (resourceId: string) => string): any => {
  return function(this) {
    let prefix = ''
    if (this.options.tag === 'Mean') {
      prefix = `${resolveStringResource('common.button.mean')} `
    } else if (this.options.tag === 'Min') {
      prefix = `${resolveStringResource('common.button.minimum')} `
    } else if (this.options.tag === 'Max') {
      prefix = `${resolveStringResource('common.button.maximum')} `
    }
    return `${prefix}${this.options.legendName}: ${formatValueWithBestUnit(this.options.legendValue, this.options.legendUnit, 1)}`
  }
}

const getResponsiveLegendRules = (data: ChartDataModel) => {
  return [
    getResponsiveRule(0, LEGEND_MARGIN_WIDTH + 2 * LEGEND_ITEM_WIDTH, data.series.length, 1),
    getResponsiveRule(LEGEND_MARGIN_WIDTH + 2 * LEGEND_ITEM_WIDTH, LEGEND_MARGIN_WIDTH + 3 * LEGEND_ITEM_WIDTH, data.series.length, 2),
    getResponsiveRule(LEGEND_MARGIN_WIDTH + 3 * LEGEND_ITEM_WIDTH, LEGEND_MARGIN_WIDTH + 4 * LEGEND_ITEM_WIDTH, data.series.length, 3),
    getResponsiveRule(LEGEND_MARGIN_WIDTH + 4 * LEGEND_ITEM_WIDTH, LEGEND_MARGIN_WIDTH + 5 * LEGEND_ITEM_WIDTH, data.series.length, 4),
    getResponsiveRule(LEGEND_MARGIN_WIDTH + 5 * LEGEND_ITEM_WIDTH, LEGEND_MARGIN_WIDTH + 6 * LEGEND_ITEM_WIDTH, data.series.length, 5),
    getResponsiveRule(LEGEND_MARGIN_WIDTH + 6 * LEGEND_ITEM_WIDTH, 1e300, data.series.length, 6),
  ]
}

const getResponsiveRule = (fromWidth: number, toWidth: number, numSeries: number, numLegendColumns: number) => {
  return {
    chartOptions: {
      chart: {
        height: `${MIN_CHART_PLOT_AREA_HEIGHT + numSeries / numLegendColumns * LEGEND_ITEM_HEIGHT}px`, // pot area + height per legend
      },
    },
    condition: {
      callback: getLegendConditionCallback(fromWidth, toWidth),
    },
  }
}

const getLegendConditionCallback = (fromWidth: number, toWidth: number): any => {
  return function(this) {
    return this.plotHeight < MIN_CHART_PLOT_AREA_HEIGHT && this.plotWidth >= fromWidth && this.plotWidth < toWidth
  }
}

const formatValueWithBestUnit = (value: number, dataUnit: string, decimals?: number, bestValue?: number): string => {
  if (value == null) {
    return 'n/a'
  }
  const displayUnit = convert().possibilities().includes(dataUnit)
    ? convert(bestValue ?? value).from(dataUnit).toBest({ exclude: ['mWh', 'Wh'], cutOffNumber: 10 }).unit
    : dataUnit
  return displayUnit !== dataUnit
    ? `${roundToDecimals(convert(value).from(dataUnit).to(displayUnit), decimals)} ${displayUnit}`
    : `${roundToDecimals(value, decimals)} ${displayUnit}`
}

const getAxisDataMax = (axis) => {
  return Math.max(Math.abs(axis.dataMax), Math.abs(axis.dataMin), Math.abs(axis.max))
}

const makeSeriesKey = (series: ChartDataSeriesModel) => {
  return `${series.id}-${series.tag}`
}

export const roundToDecimals = (value: number, decimals?: number) => {
  if (decimals === undefined || value === null || value === undefined || Number.isInteger(value)) {
    return value
  }
  return Math.abs(value) > 1 ? value.toFixed(decimals) : value.toPrecision(decimals)
}
