<template>
  <app-view>
    <icon-header
      :title="userAgreement.name"
      material-icon="menu_book"
    />
    <user-agreement-content :content="userAgreement.content"/> 
    <div v-if="!userAgreement.accepted">
      <base-text-button @buttonClicked="onAcceptUserAgreement" :enabled="!isInteracted">{{ 'common.button.accept' | translate }}</base-text-button>
    </div>
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import UserAgreementContent from './shared/UserAgreementContent.vue'
import { UserAgreementViewModel } from '../store/modules/app/models'
import StackRouter from '../router'

const App = namespace('app')

@Component({
  components: {
    AppView,
    IconHeader,
    UserAgreementContent,
  },
})
export default class AcceptUserAgreement extends Vue {
  @App.Getter public isInteracted: boolean
  @App.Getter public userAgreements: UserAgreementViewModel[]
  @App.Action public acceptUserAgreement: (type: string) => Promise<void>

  public get userAgreementType(): string {
    return this.$route.params.type
  }

  public get userAgreement(): UserAgreementViewModel {
    return this.userAgreements.find((_) => _.type === this.userAgreementType)!
  }

  public async onAcceptUserAgreement() {
    await this.acceptUserAgreement(this.userAgreement.type)
    this.navigateTo('acceptuseragreements')
  }

  public navigateTo(routeName: string) {
    Vue.$log.info('Navigating to', routeName)
    StackRouter.navigate({ name: routeName })
  }
}
</script>

<style lang="scss" scoped>
.v-btn {
  border: 1px solid white;
  margin-bottom: 20px;
}
</style>