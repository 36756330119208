<template>
  <app-view>
    <div v-for="plc in roomPlcs" :key="plc.id" class="plc-list">
      <div class="plc">
        <icon-header v-if="roomPlcs.length > 1" class="plc-name"
          :title="plc.name"
          :icon="'eco.home.white'"
        />
        <v-layout row wrap class="room-list">
          <v-flex v-for="room in roomsOfPlc(plc.id)" 
            :key="room.id" 
            :class="[
              'room-box',
              'xs6',
              isIpadMini ? 'md6' : 'md3',
              'lg2',
            ]"          >
            <div class="room-circle" @click="navigateToRoom(room)" v-ripple>
              <svg-icon :icon="room.icon" :width="35" :height="35"/>
              <div class="room-name">{{ room.name }}</div>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </div>
    <div v-if="roomPlcs.length === 0">
      {{ 'rooms.no_items' | translate }}
    </div>
  </app-view>
</template>

<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import SvgIcon from './shared/SvgIcon.vue'
import StackRouter from '../router'
import { PlcModel, RoomModel } from '../../../eco-domain-store-modules/src/plcOperation/models'
import EnvironmentService from '../../../eco-domain-store-modules/src/services/environment.service'

const App = namespace('app')
const DeviceUi = namespace('deviceUi')

@Component({
  components: {
    AppView,
    IconHeader,
    SvgIcon,
  },
})
export default class Rooms extends Vue {
  @App.Mutation public setSelectedPlcId: (plcId: string) => void
  @DeviceUi.Getter public roomPlcs: PlcModel[]
  @DeviceUi.Getter public roomsOfPlc: (plcId: string) => RoomModel[]
  @DeviceUi.Action public stopControlUpdates: () => Promise<void>

  public async mounted() {
    this.stopControlUpdates()
  }

  public navigateToRoom(room: RoomModel) {
    Vue.$log.info('Navigating to room', room.id)
    this.setSelectedPlcId(room.plcId)
    StackRouter.navigate({ name: 'room', params: { roomId: room.id } })
  }

  public get isIpadMini() {
    return EnvironmentService.isIpadMini
  }
}
</script>
<style lang='scss' scoped>
.plc-name {
  margin: 0px 20px;
}
.room-list {
  margin-bottom: 20px;
  .room-box {
    display: flex;
    justify-content: center;
    .room-circle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 105px;
      height: 105px;
      border: 2px solid #fa0f36;
      border-radius: 105px;
      margin: 10px;
      .room-name {
        max-width: 85%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 5px;
        font-size: 10px;
      }
    }
  }
}
</style>