import {
  AlarmLogUiAction,
  AlarmLogUiGetter,
  AlarmLogUiMutation,
  AlarmLogUiState,
} from '@/store/modules/alarmLogUi/types'
import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { getters } from '@/store/modules/alarmLogUi/getters'
import { state } from '@/store/modules/alarmLogUi/state'
import { mutations } from '@/store/modules/alarmLogUi/mutation'
import { actions } from '@/store/modules/alarmLogUi/actions'

const namespaced: boolean = true

export const alarmLogUi: Module<AlarmLogUiState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action: AlarmLogUiAction,
  mutation: AlarmLogUiMutation,
  getter: AlarmLogUiGetter,
}

export const domain = 'alarmLogUi'

export const vuexModule = {
  [domain]: alarmLogUi,
}
