import { GetterTree } from 'vuex'
import { ChartUiState, ChartUiGetter } from './types'
import { RootState } from '@/store/types'
import { PeriodType } from '@ecocoach/domain-store-modules/src/chart/models'
import { ChartCollectionTab, ChartTab, StatusViewChartCollection } from './models'
import { getNextPeriodStartUtc, formatDay, formatMonth, formatWeek, formatYear, getPeriodStartUtc } from './helpers'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import moment from 'moment'
import { DropdownOption } from '@ecocoach/shared-components/src'

export const getters: GetterTree<ChartUiState, RootState> = {
  [ChartUiGetter.isLoading]({ isLoading }) {
    return isLoading
  },
  [ChartUiGetter.chartCollections](_, __, rootState, rootGetters): ChartCollectionTab[] {
    const stringResource = rootGetters[`resource/${ResourceGetter.resolveStringResource}`]
    const statusView = {
      id: StatusViewChartCollection,
      text: stringResource('energy.status.menue.title'),
      materialIcon: 'power_settings_new',
      charts: [],
    } as ChartCollectionTab
    const chartCollections = rootState.chart.chartCollections.map(cc => {
      return {
        id: cc.id,
        text: cc.name,
        materialIcon: cc.icon,
        charts: cc.charts.map(c => {
          return {
            ...c,
            text: c.name,
            descriptionHtml: c.description,
          } as ChartTab
        }),
      } as ChartCollectionTab
    })
    return [statusView, ...chartCollections]
  },
  [ChartUiGetter.selectedChartCollectionId]({ selectedChartCollectionId }): string {
    return selectedChartCollectionId
  },
  [ChartUiGetter.selectedChartId]({ selectedChartId }): string {
    return selectedChartId
  },
  [ChartUiGetter.selectedChartPeriod]({ selectedChartPeriod }): PeriodType {
    return selectedChartPeriod
  },
  [ChartUiGetter.selectedNodeId]({ selectedChartId, selectedNodeId }, getters): string | null {
    const chart = getters[ChartUiGetter.chartCollections].find(cc => cc.charts.some(c => c.id === selectedChartId))!.charts.find(c => c.id === selectedChartId) as ChartTab
    const existingNode = chart.energyManagementTopologyNodes?.find(n => n.id == selectedNodeId) ?? chart.energyManagementTopologyNodes?.find(n => n.isRoot)
    return existingNode?.id ?? null
  },
  [ChartUiGetter.scaleSelected]({ selectedChartId, scaleSelected }, getters): boolean {
    const chart = getters[ChartUiGetter.chartCollections].find(cc => cc.charts.some(c => c.id === selectedChartId))!.charts.find(c => c.id === selectedChartId) as ChartTab
    return scaleSelected && chart.scalableToAuthorized
  },
  [ChartUiGetter.selectedChartPeriodStartDateTime]({ selectedChartPeriodStartDateTime }): string {
    return selectedChartPeriodStartDateTime
  },
  [ChartUiGetter.canSelectNextChartPeriod](state): boolean {
    return getNextPeriodStartUtc(state.selectedChartPeriodStartDateTime, state.selectedChartPeriod).isBefore(moment.utc(state.dateTimeUtcNow))
  },
  [ChartUiGetter.displayedChartPeriod](state): string {
    switch (state.selectedChartPeriod) {
    case PeriodType.Day: return formatDay(state.selectedChartPeriodStartDateTime)
    case PeriodType.Week: return formatWeek(state.selectedChartPeriodStartDateTime)
    case PeriodType.Month: return formatMonth(state.selectedChartPeriodStartDateTime)
    case PeriodType.Year: return formatYear(state.selectedChartPeriodStartDateTime)
    default: return ''
    }
  },
  [ChartUiGetter.selectedChartPeriodLocalDate](state): string {
    return getPeriodStartUtc(state.selectedChartPeriodStartDateTime, state.selectedChartPeriod).local().format('YYYY-MM-DD')
  },
  [ChartUiGetter.showNodeSelection](state, getters): boolean {
    const chart = getters[ChartUiGetter.chartCollections].find(cc => cc.charts.some(c => c.id === state.selectedChartId))?.charts.find(c => c.id === state.selectedChartId) as ChartTab
    return chart?.energyManagementTopologyNodes?.length > 1 ?? false
  },
  [ChartUiGetter.showScaleSelection](state, getters): boolean {
    const chart = getters[ChartUiGetter.chartCollections].find(cc => cc.charts.some(c => c.id === state.selectedChartId))?.charts.find(c => c.id === state.selectedChartId) as ChartTab
    return chart?.scalableToAuthorized ?? false
  },
  [ChartUiGetter.showSettings](state, getters): boolean {
    return getters[ChartUiGetter.showNodeSelection] || getters[ChartUiGetter.showScaleSelection]
  },
  [ChartUiGetter.nodeOptions](state, getters): DropdownOption[] {
    const chart = getters[ChartUiGetter.chartCollections].find(cc => cc.charts.some(c => c.id === state.selectedChartId))?.charts.find(c => c.id === state.selectedChartId) as ChartTab
    return (chart?.energyManagementTopologyNodes ?? [])
      .sort((first, second) => {
        // first sort by root (roots first)
        const rootSortResult = (first.isRoot === second.isRoot) ? 0 : first.isRoot ? -1 : 1
        // then sort by name (numeric)
        return rootSortResult || first.name?.localeCompare(second.name, undefined, { numeric: true })
      })
      .map(n => ({ id: n.id, label: n.name }))
  },
  [ChartUiGetter.isSeriesHidden](state) {
    return (id: string) => state.hiddenSeriesIds.includes(id)
  }, 
}

