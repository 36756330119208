<template>
  <app-view>
    <icon-header
      :title="'navigation.houses' | translate"
      icon="eco.home.white"
    />
    <list-navigation-item v-for="project in projects" :key="project.id"
      icon="eco.home.white"
      :label="project.name"
      :active="isProjectSelected(project.id)"
      @selected="onSelectProject(project.id)"
    />
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import ListNavigationItem from './shared/ListNavigationItem.vue'
import StackRouter from '../router'
import { ProjectModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'

const App = namespace('app')

@Component({
  components: {
    AppView,
    IconHeader,
    ListNavigationItem,
  },
})
export default class Projects extends Vue {
  @App.Action public selectProject: (projectId: string) => void
  @App.Getter public selectedProjectId: string
  @App.Getter public projects: ProjectModel[]

  public isProjectSelected(projectId: string) {
    return this.selectedProjectId === projectId
  }

  public async onSelectProject(projectId: string) {
    Vue.$log.info('onSelectProject', projectId)
    StackRouter.navigate({ name: 'loading' })
    await this.selectProject(projectId)
    StackRouter.navigate({ name: 'rooms' })
  }
}
</script>

<style lang="scss" scoped>
</style>