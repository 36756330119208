<template>
  <app-view>
    <icon-header
      :title="'navigation.support' | translate"
      icon="eco.helpcontent.white"
    />
    <list-navigation-item
      :label="'settings.support.contact' | translate"
      @selected="navigateTo('contact')"
    />
    <list-navigation-item
      :label="'settings.support.sharedebuginfo' | translate"
      :value="shareDebugInfo ? 'common.button.yes' : 'common.button.no' | translate"
      @selected="navigateTo('sharedebuginfo')"
    />
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import ListNavigationItem from './shared/ListNavigationItem.vue'
import { isLogRocketActive } from './../logrocket'
import StackRouter from '../router'

@Component({
  components: {
    AppView,
    IconHeader,
    ListNavigationItem,
  },
})
export default class Support extends Vue {
  public shareDebugInfo = false

  public async mounted() {
    this.shareDebugInfo = await isLogRocketActive()
  }

  public navigateTo(routeName: string) {
    Vue.$log.info('Navigating to', routeName)
    StackRouter.navigate({ name: routeName })
  }
}
</script>
<style lang="scss" scoped>
</style>
