<template>
  <v-layout>
    <v-flex v-if="icon" shrink class="header-icon">
      <v-badge :color="badgeColor" overlap>
        <template v-slot:badge v-if="!!badge">
          <div>{{badge}}</div>
        </template>
          <svg-icon :icon="icon" :width="20" :height="20" />
      </v-badge>
    </v-flex>
    <v-flex v-if="materialIcon" shrink class="header-icon">
      <v-badge :color="badgeColor" overlap>
        <template v-slot:badge v-if="!!badge">
          <div>{{badge}}</div>
        </template>
        <base-material-icon
          :icon-name="materialIcon"
          :height="20"
        />
      </v-badge>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import SvgIcon from './SvgIcon.vue'

@Component({
  components: {
    SvgIcon,
  },
})
export default class IconWithBadge extends Vue {
  @Prop({ default: '' }) public icon: string
  @Prop({ default: '' }) public materialIcon: string
  @Prop({ default: '' }) public badge: string
  @Prop({ default: '#fa0f36 '}) public badgeColor: string
}
</script>
<style lang='scss' scoped>
.header-icon {
  height: 20px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  .v-badge {
    width: 20px;
    margin-right: 15px;
  }
}
</style>
<style lang="css">
.header-icon .v-badge__badge {
  font-size: 10px;
  height: 15px;
  width: 15px;
}
</style>
