import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import storeLogger from './storeLogger'
import { RootState } from './types'
import { modules as domainModules } from '@ecocoach/domain-store-modules/src'
import { app } from './modules/app'
import { chartUi } from './modules/chartUi'
import { deviceUi } from './modules/deviceUi'
import { energyStatusUi } from '@/store/modules/energyStatusUi'
import { gradient } from './modules/gradient'
import { alarmUi } from './modules/alarmUi'
import { sceneUi } from './modules/sceneUi'
import { alarmLogUi } from '@/store/modules/alarmLogUi'
import LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex'

Vue.use(Vuex)
const logrocketPlugin = createPlugin(LogRocket)

const storeOptions: StoreOptions<RootState> = {
  modules: {
    // domain modules
    ...domainModules.alarmModule,
    ...domainModules.chartModule,
    ...domainModules.authenticationModule,
    ...domainModules.plcOperationModule,
    ...domainModules.resourceModule,
    ...domainModules.userAgreementModule,
    ...domainModules.userSettingsModule,
    ...domainModules.energyStatusModule,

    // application modules
    app,
    chartUi,
    deviceUi,
    alarmUi,
    sceneUi,
    gradient,
    alarmLogUi,
    energyStatusUi,
  },
  plugins: [ storeLogger, logrocketPlugin ],
}

export default new Vuex.Store<RootState>(storeOptions)
