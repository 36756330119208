import { GetterTree } from 'vuex'
import { DeviceUiGetter, DeviceUiState } from './types'
import { RootState } from '@/store/types'
import { DeviceModel, RoomModel, ControlModelV2, PlcModel, AppDisplayLevels } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { PlcOperationGetter } from '@ecocoach/domain-store-modules/src/plcOperation/types'
import { appDisplayLevel, getVisibleControlsSorted, sortedDevices, sortedRooms } from '@/store/utils'
import { AppGetter } from '../app/types'

export const getters: GetterTree<DeviceUiState, RootState> = {
  [DeviceUiGetter.loading]({ loading }): boolean {
    return loading
  },
  [DeviceUiGetter.roomById](_, __, rootState) {
    return (roomId: string): RoomModel => {
      return rootState.plcOperation.roomsLookup.get(roomId)!
    }
  },
  [DeviceUiGetter.deviceById](_, __, rootState) {
    return (deviceId: string): DeviceModel => {
      return rootState.plcOperation.devicesLookup.get(deviceId)!
    }
  },
  [DeviceUiGetter.controlById](_, __, rootState) {
    return (controlId: string): ControlModelV2 => {
      return rootState.plcOperation.controlsLookup.get(controlId)!
    }
  },
  [DeviceUiGetter.isFavoriteDevice](_, __, rootState) {
    return (deviceId: string): boolean => {
      return rootState.plcOperation.favorites.includes(deviceId)
    }
  },
  [DeviceUiGetter.favoritePlcs](_, getter, rootState): PlcModel[] {
    return rootState.plcOperation.plcs.filter(plc =>
      plc.projectId === rootState.app.selectedProjectId &&
      getter.favoriteDevicesOfPlc(plc.id).length > 0)
  },
  [DeviceUiGetter.favoriteDevicesOfPlc](_, __, rootState, rootGetters) {
    const favorites: DeviceModel[] = rootGetters[`plcOperation/${PlcOperationGetter.favorites}`]
    return (plcId: string): DeviceModel[] => {
      return favorites.filter(device => device.plcId === plcId)
        .sort((first, second) => {
          // sort by room sort order
          const firstRoom = rootState.plcOperation.roomsLookup.get(first.roomId)!
          const secondRoom = rootState.plcOperation.roomsLookup.get(second.roomId)!
          const roomSortResult = firstRoom.sortOrder - secondRoom.sortOrder
          if (roomSortResult !== 0) {
            return roomSortResult
          } else {
            // then by device sort order
            return first.sortOrder - second.sortOrder
          }
        })
    }
  },
  [DeviceUiGetter.favoriteControlsOfDevice](_, __, ___, rootGetters) {
    return (deviceId: string): ControlModelV2[] => {
      const controls = rootGetters[`plcOperation/${PlcOperationGetter.filteredControls}`]
      return getVisibleControlsSorted(controls).filter(c => c.deviceId === deviceId)
    }
  },
  [DeviceUiGetter.roomPlcs](_, getter, rootState): PlcModel[] {
    return rootState.plcOperation.plcs.filter(plc =>
      plc.projectId === rootState.app.selectedProjectId &&
      getter.roomsOfPlc(plc.id).length > 0)
  },
  [DeviceUiGetter.roomsOfPlc](_, __, rootState, rootGetters) {
    return (plcId: string): RoomModel[] => {
      const isEcocoachEmployee = rootGetters[`app/${AppGetter.isEcocoachEmployee}`]
      const appDisplayLevels = [
        AppDisplayLevels.DISPLAYED_ON_FIRST_LEVEL,
        AppDisplayLevels.DISPLAYED_ON_SECOND_LEVEL,
      ]      
      const hasPotentiallyVisibleControls = (roomId: string) => {
        const deviceIds = new Set(rootState.plcOperation.devicesForRoomLookup.get(roomId)?.map(d => d.id) || [])
        const isControlVisibleForDevices = (control: ControlModelV2, deviceIds: Set<string>) => {
          const deviceFilter = (c: ControlModelV2) => deviceIds.has(c.deviceId)
          const ecocoachFilter = (c: ControlModelV2) => isEcocoachEmployee || !c?.attributes?.ecocoachOnly
          const appDisplayLevelFilter = (c: ControlModelV2) => appDisplayLevels.includes(appDisplayLevel(c))
          return deviceFilter(control) && appDisplayLevelFilter(control) && ecocoachFilter(control)
        }
        return rootState.plcOperation.controls.some(c => isControlVisibleForDevices(c, deviceIds))
      }

      const rooms = rootState.plcOperation.roomsForPlcLookup.get(plcId) || []
      return sortedRooms(rooms.filter(room => hasPotentiallyVisibleControls(room.id)))
    }
  },
  [DeviceUiGetter.roomDevicesOfRoom](_, getter, rootState) {
    return (roomId: string): DeviceModel[] => {
      const devices = rootState.plcOperation.devicesForRoomLookup.get(roomId) || []
      return sortedDevices(devices.filter(d => getter.roomControlsOfDevice(d.id).length > 0))
    }
  },
  [DeviceUiGetter.roomControlsOfDevice](_, __, ___, rootGetters) {
    return (deviceId: string): ControlModelV2[] => {
      const controls = rootGetters[`plcOperation/${PlcOperationGetter.filteredControls}`]
      return getVisibleControlsSorted(controls).filter(c => c.deviceId === deviceId)
    }
  },
  [DeviceUiGetter.deviceControlsOfDevice](_, __, ___, rootGetters) {
    return (deviceId: string): ControlModelV2[] => {
      const controls = rootGetters[`plcOperation/${PlcOperationGetter.filteredControls}`]
      return getVisibleControlsSorted(controls).filter(c => c.deviceId === deviceId)
    }
  },
}
