<template>
  <v-layout row align-center justify-content, justify-center class="icon-header">
    <v-flex shrink>
      <icon-with-badge
        :icon="icon"
        :material-icon="materialIcon"
        :badge="badge"
      />
    </v-flex>
    <v-flex shrink class="header-title">
      {{title}}
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import IconWithBadge from './IconWithBadge.vue'

@Component({
  components: {
    IconWithBadge,
  },
})
export default class IconHeader extends Vue {
  @Prop({ default: '' }) public icon: string
  @Prop({ default: '' }) public materialIcon: string
  @Prop({ default: '' }) public title: string
  @Prop({ default: '' }) public badge: string
}
</script>
<style lang='scss' scoped>
.icon-header {
  flex: 0;
  .header-title {
    text-align: left;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;      
  }
}
</style>

<style lang="css">
</style>
