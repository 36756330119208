import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'
import { ChartUiAction, ChartUiGetter, ChartUiMutation, ChartUiState } from './types'
import { RootState } from '@/store/types'

const namespaced: boolean = true

export const chartUi: Module<ChartUiState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
}

export const types = {
  action:  ChartUiAction,
  mutation: ChartUiMutation,
  getter: ChartUiGetter,
}

export const domain = 'chartUi'

export const vuexModule = {
  [domain]: chartUi,
}
