import { GetterTree } from 'vuex'
import { AppGetter, AppState } from './types'
import { RootState } from '@/store/types'
import { TabModel, UserAgreementViewModel } from './models'
import { Services } from '@ecocoach/domain-store-modules'
import { UserAgreementModel } from '@ecocoach/domain-store-modules/src/userAgreement/models'
import { Route } from 'vue-router'
import compareVersions from 'compare-versions'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { PlcModel, ProjectModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { UserSettingsGetter } from '@ecocoach/domain-store-modules/src/userSettings/types'

const routesWithoutNavigation = ['update', 'acceptuseragreements', 'acceptuseragreement', 'welcome']

const routesWithPlcNameHeader = ['room', 'roomdevice']

export const getters: GetterTree<AppState, RootState> = {
  [AppGetter.showNavigationForRoute](state) {
    return (route: Route): boolean => {
      return !state.isLoading && !routesWithoutNavigation.includes(route.name!)
    }
  },
  [AppGetter.showPlcNameInHeaderForRoute]() {
    return (route: Route): boolean => {
      return routesWithPlcNameHeader.includes(route.name!)
    }
  },
  [AppGetter.updateAvailable]({ versionInfo }, localGetters): boolean {
    const installedVersion = localGetters[AppGetter.version]
    const currentVersion = versionInfo && versionInfo.currentVersion || ''
    return (currentVersion && compareVersions.compare(installedVersion, currentVersion, '<')) || false
  },
  [AppGetter.updateRequired]({ versionInfo }, localGetters): boolean {
    const installedVersion = localGetters[AppGetter.version]
    const minimumVersion = versionInfo && versionInfo.minimumVersion || ''
    return (minimumVersion && compareVersions.compare(installedVersion, minimumVersion, '<')) || false
  },
  [AppGetter.isInteracted]({ interacted }): boolean {
    return interacted
  },
  [AppGetter.version](): string {
    return process.env.APP_VERSION || ''
  },
  [AppGetter.buildNumber](): string {
    return process.env.BUILD_NUMBER || ''
  },
  [AppGetter.tabs](state: AppState, getter, _, rootGetters): TabModel[] {
    let resolvedTabs = state.tabs.map((tab) => {
      const resolvedTab = {
        id: tab.id,
        text: rootGetters[`resource/${ResourceGetter.dictionary}`](tab.text),
        icon: rootGetters[`resource/${ResourceGetter.svgIcon}`](tab.icon),
      }
      return resolvedTab
    })
    if (!getter.showEnergyNextTab) {
      resolvedTabs = resolvedTabs.filter(tab => tab.id !== 'energynext')
    }
    return resolvedTabs
  },
  [AppGetter.isLoading](state: AppState): boolean {
    return state.isLoading
  },
  [AppGetter.selectedProjectId](state: AppState): string {
    return state.selectedProjectId
  },
  [AppGetter.selectedProject]({ selectedProjectId }, __, rootState): ProjectModel | null {
    return rootState.plcOperation.projects.find(p => p.id === selectedProjectId) ?? null
  },
  [AppGetter.selectedPlcId]({ selectedPlcId }): string {
    return selectedPlcId
  },
  [AppGetter.selectedPlc]({ selectedPlcId }, __, rootState): PlcModel | null {
    return rootState.plcOperation.plcs.find(p => p.id === selectedPlcId) ?? null
  },
  [AppGetter.userAgreements](_, __, rootState, rootGetters): UserAgreementViewModel[] {
    const isAccepted = (document: UserAgreementModel): boolean => {
      return rootState.userAgreement.acceptedUserAgreements
        .some(d => d.documentType === document.documentType && d.documentVersion === document.documentVersion)
    }
    return rootState.userAgreement.currentUserAgreements.map(doc => ({
      type: doc.documentType,
      version: doc.documentVersion,
      name: rootGetters[`resource/${ResourceGetter.dictionary}`](doc.nameResourceId),
      content: rootGetters[`resource/${ResourceGetter.dictionary}`](doc.contentResourceId),
      accepted: isAccepted(doc),
    }))
  },
  [AppGetter.languages](): Array<{ id: string, label: string }> {
    return [
      { id: 'de', label: 'Deutsch' },
      { id: 'en-UK', label: 'English' },
      { id: 'it', label: 'Italiano' },
    ]
  },
  [AppGetter.selectedLanguage](state: AppState): string {
    return state.selectedLanguage
  },
  [AppGetter.includeDemoProjects](state: AppState): boolean | null {
    return state.includeDemoProjects
  },
  [AppGetter.isEmailMfaEnabled](_, __, ___, rootGetters): boolean | null {
    return rootGetters[`userSettings/${UserSettingsGetter.isEmailMfaEnabled}`]
  },
  [AppGetter.isEcocoachEmployee](_, localGetters) {
    return localGetters[AppGetter.userIdentifier].endsWith('@ecocoach.com')
  },
  [AppGetter.userIdentifier](_, __, rootState): string {
    return rootState.authentication.loggedInUser.userIdentifier
  },
  [AppGetter.isMobile]() {
    return Services.EnvironmentService.isRunningOnAndroid || Services.EnvironmentService.isRunningOnIos
  },
  [AppGetter.toast]({ toast }) {
    return toast
  },
  [AppGetter.showEnergyNextTab](_, __, rootState) {
    return !!rootState.chart.chartCollections?.length
  },
  [AppGetter.projects](state, __, rootState): ProjectModel[] {
    return rootState.plcOperation.projects.filter(p => !p.isDemoProject || state.includeDemoProjects === true)
  },
  [AppGetter.plcs](state, __, rootState): PlcModel[] {
    return rootState.plcOperation.plcs.filter(plc => plc.projectId === state.selectedProjectId)
  },
  [AppGetter.hasDemoProjects](_, __, rootState): boolean {
    return rootState.plcOperation.projects.some(p => p.isDemoProject)
  },
}
