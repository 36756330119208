<template>
  <base-base64-icon
    :base64Icon="iconSrc" class="svg-icon"
    :width="width"
    :height="height"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
  },
})
export default class SvgIcon extends Vue {
  @Prop() public icon: string
  @Prop() public resolveIcon: (key: string) => string
  @Prop({ default: 20 }) public width: number
  @Prop({ default: 20 }) public height: number

  public get iconSrc() {
    return this.resolveIcon(this.icon || 'eco.default.white')
  }
}
</script>
<style lang='scss' scoped>
.svg-icon {
  background: transparent;
  border-width: 0px;
  outline: none;
}
</style>
