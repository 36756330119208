<template>
  <app-view>
    <template v-slot:actions>
      <icon-button
        @buttonClicked="showDeleteConfirmationDialog = true"
        type="materialIcon"
        materialIconName="delete"
        :height="20"
        :width="20"
        :enabled="!isInteracted && !!scene.scheduleId"
      />
      <icon-button
        @buttonClicked="onSaveSchedule"
        type="materialIcon"
        materialIconName="save"
        :height="20"
        :width="20"
        :enabled="!isInteracted && sceneSavable"
      />
    </template>
    <div class="schedule">
      <v-layout column align-center>
        <v-flex class="activate-toggle">
          <v-layout row align-center justify-content>
            <div class="activate-label">{{ 'scenes.schedule.activate' | translate }}</div>
            <base-toggle-button
              :value="scene.scheduleEnabled"
              @buttonToggled="onToggleSchedule"
            />
          </v-layout>
        </v-flex>
        <v-flex class="title">
          {{ 'scenes.schedule.definePlaytime' | translate }}
        </v-flex>
        <v-flex v-if="!isRecurringSchedule" class="date-picker">
          <date-select
            :value="scene.scheduleLocalDate"
            :label="'consumption.date' | translate"
            :keyboard="false"
            :language="selectedLanguage"
            :min="minDate"
            @changed="setScheduleDate($event)"
          />
        </v-flex>
        <v-flex class="time-picker">
          <time-select 
            :value="scene.scheduleLocalTime"
            :label="'scenes.schedule.localtime' | translate"
            :keyboard="false"
            :min="minTime"
            @changed="setScheduleTime($event)"
          />
        </v-flex>
        <v-flex class="type-selector">
          <form-field :text="'scenes.schedule.recurring' | translate" class="field">
            <base-single-select
              :options="scheduleTypeOptions"
              option-value="value"
              option-label="label"
              :selectedValue="scene.scheduleType"
              @selected="setScheduleType($event)"
            />
          </form-field>
        </v-flex>
        <v-flex v-if="isCustomSchedule" class="days-selector">
          <multi-day-picker
            :options="scheduleDaysOptions"
            :selected-values="scene.scheduleRecurringDays"
            @selected="setScheduleDays($event)"
          />
        </v-flex>
      </v-layout>
    </div>
    <confirm-dialog
      :show="showDeleteConfirmationDialog"
      :header="'common.button.delete' | translate"
      :text="'common.dialog.confirm' | translate"
      :confirm-button-text="'OK'"
      :cancel-button-text="'common.button.cancel' | translate"
      @confirmed="onDeleteSchedule"
      @cancelled="showDeleteConfirmationDialog = false"
    />
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import MultiDayPicker from './shared/MultiDayPicker.vue'
import StackRouter from '../router'
import { DaysEnum } from '../../../eco-domain-store-modules/src/plcOperation/models'
import { SceneDetailsViewModel, ScheduleType, SelectOption } from '../store/modules/sceneUi/models'
import moment from 'moment'
(moment as any).suppressDeprecationWarnings = true

const App = namespace('app')
const SceneUi = namespace('sceneUi')

@Component({
  components: {
    AppView,
    MultiDayPicker,
  },
})
export default class Schedule extends Vue {
  @App.Getter public isInteracted: boolean
  @SceneUi.Getter public scene: SceneDetailsViewModel
  @SceneUi.Getter public sceneSavable: boolean
  @SceneUi.Getter public scheduleTypeOptions: SelectOption[]
  @SceneUi.Getter public scheduleDaysOptions: SelectOption[]
  @SceneUi.Mutation public setScheduleEnabled: (isEnabled: boolean) => void
  @SceneUi.Mutation public setScheduleDays: (days: DaysEnum[]) => void
  @SceneUi.Action public setScheduleType: (type: ScheduleType) => Promise<void>
  @SceneUi.Action public setScheduleDate: (date: string) => Promise<void>
  @SceneUi.Action public setScheduleTime: (time: string) => Promise<void>
  @SceneUi.Action public loadSchedule: (sceneId: string) => Promise<void>
  @SceneUi.Action public deleteSchedule: () => Promise<void>
  @SceneUi.Action public createSchedule: () => Promise<void>
  @SceneUi.Action public saveSchedule: () => Promise<void>
  @App.Getter public selectedLanguage: string

  public showDeleteConfirmationDialog = false

  public beforeMount() {
    this.loadSchedule(this.sceneId)
    this.setScheduleEnabled(true)
  }

  public get sceneId(): string {
    return this.$route.params.sceneId
  }

  public get isRecurringSchedule() {
    return this.scene.scheduleType !== ScheduleType.oneTimePlay
  }

  public get isCustomSchedule() {
    return this.scene.scheduleType === ScheduleType.userdefined
  }

  public get minDate(): string {
    return moment().format('YYYY-MM-DD')
  }

  public get minTime(): string | undefined {
    if (!this.isRecurringSchedule && moment(this.scene.scheduleLocalDate).isSameOrBefore(moment().startOf('day'))) {
      return moment().add(1, 'minute').format('HH:mm')
    }
    return undefined
  }

  public async onDeleteSchedule() {
    await this.deleteSchedule()
    StackRouter.navigate({ name: 'scenes' })
  }

  public async onSaveSchedule() {
    if (this.scene.scheduleId) {
      await this.saveSchedule()
    } else {
      await this.createSchedule()
    }
    StackRouter.navigate({ name: 'scenes' })
  }

  public onToggleSchedule() {
    this.setScheduleEnabled(!this.scene.scheduleEnabled)
  }
}
</script>
<style lang='scss' scoped>
.schedule {
  .activate-toggle {
    margin-bottom: 20px;
    .activate-label {
      font-size: 16px;
      margin-right: 10px;
    }
  }
  .title {
    margin-bottom: 20px;
  }
}

</style>
