
import { ChartUiState } from './types'
import { PeriodType } from '@ecocoach/domain-store-modules/src/chart/models'
import { StatusViewChartCollection } from './models'
import moment from 'moment'

export const state: ChartUiState = {
  isLoading: false,
  selectedChartCollectionId: StatusViewChartCollection, // default
  selectedChartId: '',
  selectedChartPeriod: PeriodType.Day,
  selectedChartPeriodStartDateTime: '',
  selectedNodeId: null,
  scaleSelected: false,
  dateTimeUtcNow: moment.utc().toISOString(),
  hiddenSeriesIds: [],
}
