<template>
  <app-view>
    <v-layout column align-center class="container">
      <svg-icon class="banner" :icon="'eco.banner.switzerland.white'" :width="250" :height="60"/>
      <div class="title-area">
        <p>{{ 'information.version' | translate }}: {{ version }}</p>
      </div>
      <div class="info-area">
        <p>{{ 'app.version.updateneeded' | translate }}</p>
        <base-text-button @buttonClicked="onUpdate">{{ 'common.button.update' | translate }}</base-text-button>
      </div>
    </v-layout>
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import SvgIcon from './shared/SvgIcon.vue'
import { openInStore } from '../environment'

const App = namespace('app')

@Component({
  components: {
    AppView,
    SvgIcon,
  },
})
export default class Update extends Vue {
  @App.Getter public version: string

  public onUpdate() {
    openInStore()
  }
}
</script>
<style lang="scss" scoped>
.container {
  margin-top: 40px;
  font-size: 16px;
  .title-area {
    margin-bottom: 20px;
  }
  .banner {
    margin-bottom: 20px;
  }
  .info-area {
    margin-bottom: 20px;
  }
  .v-btn {
    border: 1px solid white;
  }
}
</style>