<template>
  <app-view>
    <icon-header
      title="Backend environment"
      material-icon="bug_report"
    />
    <list-navigation-item v-for="environment in backendEnvironments" :key="environment"
      :label="environment"
      :active="isSelectedBackendEnvironment(environment)"
      @selected="onSelectBackendEnvironment(environment)"
    />
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import ListNavigationItem from './shared/ListNavigationItem.vue'
import ApiService, { BackendEnvironmentTypes, BACKEND_ENVIRONMENT_STORAGE_KEY } from '../../../eco-domain-store-modules/src/services/api.service'
import AppDataStorageService from '../../../eco-domain-store-modules/src/services/appdatastorage.service'
import { AppIdMobileApp } from '@ecocoach/domain-store-modules/src/alarm/types'

const Authentication = namespace('authentication')

@Component({
  components: {
    AppView,
    IconHeader,
    ListNavigationItem,
  },
})
export default class BackendEnvironment extends Vue {
  @Authentication.Action public logout: () => Promise<void>

  public get backendEnvironments(): string[] {
    return [
      BackendEnvironmentTypes.PRODUCTION,
      BackendEnvironmentTypes.PREPRODUCTION,
      BackendEnvironmentTypes.STAGING,
      BackendEnvironmentTypes.DEVELOPMENT,
    ]
  }

  public get selectedBackendEnvironment(): string {
    return ApiService.selectedBackendEnvironment()
  }

  public isSelectedBackendEnvironment(enviromnent: string): boolean {
    return enviromnent === this.selectedBackendEnvironment
  }

  public async onSelectBackendEnvironment(backendEnvironment: BackendEnvironmentTypes) {
    Vue.$log.info('selectBackendEnvironment', backendEnvironment)
    if (this.backendEnvironments.includes(backendEnvironment) && backendEnvironment !== this.selectedBackendEnvironment) {
      await AppDataStorageService.setForAppId(AppIdMobileApp, BACKEND_ENVIRONMENT_STORAGE_KEY, backendEnvironment)
      Vue.$log.info('logout')
      await this.logout()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>