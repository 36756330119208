<template>
  <modal-dialog 
    :show="show"
    :minimal="true"
  >
    <v-layout column class="icon-picker-dialog">
      <v-flex class="icon-picker-dialog-title">
        {{ 'scenes.scene.modal.color' | translate }}
      </v-flex>
      <v-layout row wrap class="icon-picker-item-list">
        <v-flex shrink v-for="gradient in gradients" class="option color-option"
          :key="gradient"
          :class="{ 'selected': gradient === selectedGradient}"
          :style="gradientStyle(gradient)"
          @click="onGradientSelected(gradient)"
        />
      </v-layout>
      <v-flex class="icon-picker-dialog-title">
        {{ 'scenes.scene.modal.icon' | translate }}
      </v-flex>
      <v-layout row wrap class="icon-picker-item-list">
        <v-flex shrink v-for="icon in icons" class=" option icon-option"
          :key="icon"
          :class="{ 'selected': icon === selectedIcon}"
          @click="onIconSelected(icon)"
        >
          <svg-icon :icon="icon" :width="20" :height="20" />
        </v-flex>
      </v-layout>
      <div>
        <base-text-button @buttonClicked="onOk" :enabled="!isInteracted">{{ 'common.button.close' | translate }}</base-text-button>
      </div>
    </v-layout>
  </modal-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import SvgIcon from './SvgIcon.vue'
import { Gradients } from '@ecocoach/shared-components/src'

@Component({
  components: {
    SvgIcon,
  },
})
export default class IconPickerDialog extends Vue {
  @Prop() public show: boolean
  @Prop() public icons: string[]
  @Prop() public gradients: string[]
  @Prop() public selectedIcon: string
  @Prop() public selectedGradient: string

  @Emit('closed')
  public onOk() {
    return true
  }

  @Emit('gradientSelected')
  public onGradientSelected(gradient: string) {
    return gradient
  }

  @Emit('iconSelected')
  public onIconSelected(icon: string) {
    return icon
  }

  public gradientStyle(gradient: string) {
    return {
      background: `linear-gradient(-135deg, ${Gradients.primaryColor(gradient)} 0%, ${Gradients.secondaryColor(gradient)} 100%`,
    }
  }
}
</script>
<style lang='scss' scoped>
.icon-picker-dialog {
  .icon-picker-dialog-title {
    text-align: left;
    font-size: 14px;
    margin-left: 5px;
  }
  .icon-picker-item-list {
    .option {
      height: 35px;
      width: 35px;
      border-radius: 5px;
      margin: 5px;
      &.selected {
        border: 2px solid white;
      }
    }
    .color-option {
    }
    .icon-option {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.icon-with-gradient {
  height: 35px;
  width: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon-with-gradient-icon {
  }
}
.v-btn {
  border: 1px solid white;
}
</style>
    
