import { GetterTree } from 'vuex'
import { EnergyStatusUiGetter } from './types'
import { RootState } from '@/store/types'
import { EnergyStatusViewModel } from '@ecocoach/shared-components/src/components/energyStatusView/models'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { EnergyStatusGetter } from '@ecocoach/domain-store-modules/src/energyStatus/types'

export const getters: GetterTree<{}, RootState> = {
  [EnergyStatusUiGetter.energyStatusViewModel](_, __, ___, rootGetters) {
    return {
      producers: rootGetters[`energyStatus/${EnergyStatusGetter.producers}`],
      consumers: rootGetters[`energyStatus/${EnergyStatusGetter.consumers}`],
      productionContributions: rootGetters[`energyStatus/${EnergyStatusGetter.productionContributions}`],
      consumptionContributions: rootGetters[`energyStatus/${EnergyStatusGetter.consumptionContributions}`],
      resolveIcon: rootGetters[`resource/${ResourceGetter.svgIcon}`],
    } as EnergyStatusViewModel
  },
}
