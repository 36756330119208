<template>
  <app-view>
    <icon-header
      :title="'settings.account.language' | translate"
      icon="eco.account.white"
    />
    <list-navigation-item v-for="language in languages" :key="language.id"
      :label="language.label"
      :active="isSelectedLanguage(language.id)"
      @selected="onSelectLanguage(language.id)"
    />
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import ListNavigationItem from './shared/ListNavigationItem.vue'

const App = namespace('app')

@Component({
  components: {
    AppView,
    IconHeader,
    ListNavigationItem,
  },
})
export default class Language extends Vue {
  @App.Action public selectLanguage: (language: string) => void
  @App.Getter public languages: Array<{ id: string, label: string }>
  @App.Getter public selectedLanguage: string

  public isSelectedLanguage(id: string): boolean {
    return id === this.selectedLanguage
  }

  public onSelectLanguage(value: string) {
    Vue.$log.info('selectLanguage', value)
    if (value !== this.selectedLanguage) {
      this.selectLanguage(value)
      window.location.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>