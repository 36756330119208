<template>
  <app-view>
    <icon-header
      title="Log level"
      material-icon="bug_report"
    />
    <list-navigation-item v-for="logLevel in logLevels" :key="logLevel"
      :label="logLevel"
      :active="isSelectedLogLevel(logLevel)"
      @selected="onSelectLogLevel(logLevel)"
    />
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import ListNavigationItem from './shared/ListNavigationItem.vue'
import AppDataStorageService from '../../../eco-domain-store-modules/src/services/appdatastorage.service'
import { LogLevelStorageKey } from '../store/modules/app/models'

@Component({
  components: {
    AppView,
    IconHeader,
    ListNavigationItem,
  },
})
export default class LogLevel extends Vue {
  public selectedLogLevel = ''

  public async mounted() {
    this.selectedLogLevel = await AppDataStorageService.get(LogLevelStorageKey)
  }

  public get logLevels(): string[] {
    return [
      'debug',
      'info',
      'warn',
      'error',
    ]
  }

  public isSelectedLogLevel(value: string): boolean {
    return value === this.selectedLogLevel
  }

  public async onSelectLogLevel(value: string) {
    Vue.$log.info('selectLogLevel', value)
    if (this.logLevels.includes(value) && value !== this.selectedLogLevel) {
      await AppDataStorageService.set(LogLevelStorageKey, value)
      window.location.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>