<template>
  <app-view>
    <icon-header
      :title="'settings.account.demoprojects' | translate"
      icon="eco.account.white"
    />
    <list-navigation-item
      :label="'common.button.yes' | translate"
      :active="includeDemoProjects"
      @selected="onSetIncludeDemoProjects(true)"
    />
    <list-navigation-item
      :label="'common.button.no' | translate"
      :active="!includeDemoProjects"
      @selected="onSetIncludeDemoProjects(false)"
    />
  </app-view>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AppView from './AppView.vue'
import IconHeader from './shared/IconHeader.vue'
import ListNavigationItem from './shared/ListNavigationItem.vue'
import StackRouter from '../router'
import { ProjectModel } from '@ecocoach/domain-store-modules/src/plcOperation/models'

const App = namespace('app')

@Component({
  components: {
    AppView,
    IconHeader,
    ListNavigationItem,
  },
})
export default class DemoProjects extends Vue {
  @App.Action public setIncludeDemoProjects: (includeDemoProjects: boolean) => void
  @App.Getter public includeDemoProjects: boolean | null
  @App.Getter public projects: ProjectModel[]

  public async onSetIncludeDemoProjects(value: boolean) {
    Vue.$log.info('setIncludeDemoProjects', value)
    if (value !== this.includeDemoProjects) {
      await this.setIncludeDemoProjects(value)
    }
    if (value && this.projects.length === 1) {
      StackRouter.navigate({ name: 'rooms'} )
    } else {
      StackRouter.goBack()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>