<template>
  <div class="status-view-main">
    <div class="status-view-top">
      <div class="scroll-container">
        <div
          v-for="device in producers"
          :key="device.id" 
          class="status-view-item">
          <status-view-device
            :device="device"
            :resolve-icon="model.resolveIcon"
            @selected="onSelected"
          />
        </div>
      </div>
    </div>
    <div class="status-view-center">
      <div class="status-view-producer-line">
        <summary-line :contributions="productionContributions"/>
      </div>
      <div class="status-view-summary-line"/>
      <div class="status-view-consumer-line">
        <summary-line :contributions="consumptionContributions"/>
      </div>
    </div>
    <div class="status-view-bottom">
      <div class="scroll-container">
        <div
          v-for="device in consumers"
          :key="device.id" 
          class="status-view-item">
          <status-view-device 
            :rotate="true" 
            :device="device"
            :resolve-icon="model.resolveIcon"
            @selected="onSelected"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import StatusViewDevice from './ui/StatusViewDevice.vue'
import SummaryLine from './ui/SummaryLine.vue'

import type { EnergyStatusViewModel } from './models'

@Component({
  components: {
    StatusViewDevice,
    SummaryLine,
  },
})
export default class EnergyStatusView extends Vue {
  @Prop() public model: EnergyStatusViewModel

  public get producers() {
    return this.model.producers
  }

  public get consumers() {
    return this.model.consumers
  }

  public get productionContributions() {
    return this.model.productionContributions
  }

  public get consumptionContributions() {
    return this.model.consumptionContributions
  }

  @Emit('selected')
  public onSelected(deviceId: string) {
    return deviceId
  }
}
</script>

<style lang="scss" scoped>
.status-view-main {
	flex: 1 1 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: calc(100% - 55px);
	.status-view-top {
		flex: 1 1 100%;
    height: calc(50% - 35px);
		display:flex;
	}
	.status-view-center {
		flex: 0 0 35px; // 10+10+15 px from inside
		display: flex;
		flex-direction: column;
		.status-view-producer-line {
			height: 10px;
		}
		.status-view-summary-line {
			height: 15px;
		}
		.status-view-consumer-line {
			height: 10px;
		}
	}
	.status-view-bottom {
    height: calc(50% - 35px);
		flex: 1 1 100%;
		display: flex;
	}
}
.scroll-container {
	flex: 1 1 100%;
	display: flex;
	flex-direction: row;
	overflow-x: scroll;
	overflow-y: hidden;
	width: 100%;
	.status-view-item {
		flex: 1 1 100%;
	}
}
// hide scrollbars (eventhough it is scrollable)
.scroll-container::-webkit-scrollbar {
	display: none;
}
</style>
 