import EnvironmentService from '@ecocoach/domain-store-modules/src/services/environment.service'
import Vue from 'vue'
import store from './store'
import { LogLevelStorageKey, ShareDebugInfoActivationTimeStampStorageKey, ShareDebugInfoStorageKey } from './store/modules/app/models'
import { AppGetter } from './store/modules/app/types'
import AppDataStorageService from '@ecocoach/domain-store-modules/src/services/appdatastorage.service'
import LogRocket from 'logrocket'
import moment from 'moment'

const LogRocketProjectId = 'ahgwco/eco-mobile-app'
const ActivationPeriodInDays = 3

export async function initializeLogRocket() {
  const shareDebugInfo = await isLogRocketActive()
  if (shareDebugInfo) {
    // deactivate after specific amount of time
    const activationTimeStamp = await AppDataStorageService.get(ShareDebugInfoActivationTimeStampStorageKey)
    if (activationTimeStamp) {
      if (moment.utc(activationTimeStamp).add(ActivationPeriodInDays, 'd').isBefore(moment.utc())) {
        Vue.$log.debug('logRocket:reset')
        await activateLogRocket(false)
        return
      }
    }

    Vue.$log.debug('logRocket:initialize')
    const version = store.getters[`app/${AppGetter.version}`]
    const release = store.getters[`app/${AppGetter.buildNumber}`]
    LogRocket.init(LogRocketProjectId, {
      release: `${version} - ${release}`,
    })
  }
}

export async function identifyLogRocket() {
  const shareDebugInfo = await isLogRocketActive()
  if (shareDebugInfo) {
    const userIdentifier = store.getters[`app/${AppGetter.userIdentifier}`]
    const device = EnvironmentService.deviceModel || 'Browser'
    const logRocketIdentifier = `${userIdentifier}-${device}`
    Vue.$log.debug('logRocket:identify as', logRocketIdentifier)
    LogRocket.identify(logRocketIdentifier, {
      devicePlatform: EnvironmentService.devicePlatform,
      deviceModel: EnvironmentService.deviceModel,
    })
  }
}

export async function isLogRocketActive() {
  return !!await AppDataStorageService.getBoolean(ShareDebugInfoStorageKey)
}

export async function activateLogRocket(active: boolean) {
  Vue.$log.debug('logRocket:activate', active)
  if (active) {
    await AppDataStorageService.setBoolean(ShareDebugInfoStorageKey, true)
    await AppDataStorageService.set(ShareDebugInfoActivationTimeStampStorageKey, moment().toISOString())
    await AppDataStorageService.set(LogLevelStorageKey, 'info')
  } else {
    await AppDataStorageService.delete(ShareDebugInfoStorageKey)
    await AppDataStorageService.delete(ShareDebugInfoActivationTimeStampStorageKey)
    await AppDataStorageService.set(LogLevelStorageKey, 'error')
  }
}
