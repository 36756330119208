<template>
  <div>
    <svg 
      ref="svg"
      width="100%" 
      height="100%" 
      viewBox="0 0 100 100">
      <defs>
        <linearGradient
          :id="'circleGradient_' + gradient"
          x1="0.7"
          y1="0.3" 
          x2="0.3" 
          y2="0.7">
          <stop 
            :stop-color="gradientPrimaryRgba" 
            offset="0%"/>
          <stop
            :stop-color="gradientSecondaryRgba" 
            offset="100%"/>
        </linearGradient>
        <radialGradient 
          id="circleShadowGradient">
          <stop 
            offset="0%"
            stop-opacity="1"
            stop-color="rgb(21,22,37)"/>
          <stop 
            offset="70%" 
            stop-opacity="1"
            stop-color="rgb(21,22,37)"/>
          <stop 
            offset="80%" 
            stop-opacity=".7"
            stop-color="rgb(21,22,37)"/>
          <stop 
            offset="90%" 
            stop-opacity=".2"
            stop-color="rgb(21,22,37)"/>
          <stop 
            offset="100%" 
            stop-opacity="0"
            stop-color="rgb(21,22,37)"/>
        </radialGradient>
      </defs>
      <!-- circle shadow -->
      <circle
        id="circle-shadow"
        :r="40 + 15" 
        cx="50%" 
        cy="50%" 
        fill="url(#circleShadowGradient)"/>
      <circle
        id="baseCircle"
        cx="50%" 
        cy="50%" 
        r="40.9" 
        fill="rgb(19,30,53)"/>
      <circle 
        id="valueOuterRing" 
        :stroke="'url(#circleGradient_' + gradient +')'" 
        cx="50%" 
        cy="50%"  
        r="40" 
        stroke-width="1.8"
        fill="transparent"/><circle 
          id="baseRing" 
          cx="50%" 
          cy="50%" 
          r="33.8"
          stroke="black" 
          stroke-width="3.76"
          fill="transparent"/>
      <circle 
        v-if="devicePercentage === null"
        id="valueRing1"
        :r="33.8" 
        :stroke="'url(#circleGradient_' + gradient +')'" 
        cx="50%" 
        cy="50%" 
        stroke-width="3"
        fill="transparent" 
        stroke-linecap="round"/>
      <circle 
        v-if="devicePercentage !== null"
        id="valueRing2"
        :r="33.8"
        :stroke="'url(#circleGradient_' + gradient +')'" 
        :stroke-width="3.76" 
        :stroke-dasharray="2 * Math.PI * 33.8" 
        :stroke-dashoffset="(2 * 33.8 * Math.PI * (1-(devicePercentage/100)))" 
        class="valueCircle" 
        cx="50%" 
        cy="50%" 
        fill="transparent" 
        stroke-linecap="round"/>
      <circle 
        v-if="devicePercentage !== null"
        id="endPointInner" 
        :cx="50 + 33.8 * (Math.cos((2 * Math.PI / 100 * devicePercentage) - 0.5 * Math.PI)) " 
        :cy="50 + 33.8 * (Math.sin((2 * Math.PI / 100 * devicePercentage) - 0.5 * Math.PI))" 
        :r="2" 
        fill="#fff"/>
      <circle 
        v-if="devicePercentage !== null" 
        id="endPointOuter" 
        :cx="50 + 33.8 * (Math.cos((2 * Math.PI / 100 * devicePercentage) - 0.5 * Math.PI)) " 
        :cy="50 + 33.8 * (Math.sin((2 * Math.PI / 100 * devicePercentage) - 0.5 * Math.PI))" 
        :r="3.5" 
        :stroke-width="1" 
        fill="transparent" 
        stroke="#fff"/>
      <text 
        v-if="devicePercentage !== null"
        id="circleValue" 
        :font-size="12"	  
        :font-style="'normal'"
        :font-family="'Titillium Web'" 
        :font-weight="600"  
        :x="50" 
        :y="70" 
        text-anchor="middle"
        fill="white"
        alignment-baseline="middle">{{ device.percentageDisplayValue }}</text>
      <image
        id="circleImage"
        :xlink:href="resolveIcon(icon)" 
        :x="'40'" 
        :y="'40'"
        :height="20"
        :width="20"
        @click.prevent="onClick"/>
    </svg>
  </div>	
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Gradients from './../../deviceControls/v1/shared/gradients'

import { EnergyStatusDeviceViewModel } from './../models'

@Component({
  components: {
  },
})
export default class EnergyIconCircle extends Vue {
  @Prop() public device: EnergyStatusDeviceViewModel
  @Prop() public resolveIcon: (key: string) => string

  public get icon() {
    return this.device.icon
  }

  public get gradient() {
    // fallback to power gradient
    return this.device.percentageGradient ?? this.device.powerGradient ?? ''
  }

  public get devicePercentage() {
    return this.device.percentageValue
  }

  public get gradientPrimaryRgba() {
    return Gradients.primaryColorRgbaFunction(this.gradient, 0.65)
  }

  public get gradientSecondaryRgba() {
    return Gradients.secondaryColorRgbaFunction(this.gradient, 0.65)
  }

  @Emit('click')
  public onClick() {
    return
  }
}
</script>

<style lang="scss" scoped>
.circleTitle {
	letter-spacing: 1px;
}
.circleValue {
	letter-spacing: 1px;
}
.valueCircle {
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
}
</style>
