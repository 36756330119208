import { Store } from 'vuex'
import { RootState } from './types'
import Vue from 'vue'

export default function(store: Store<RootState>) {
  store.subscribe(({ type, payload }) => {
    Vue.$log.debug('mutation', 'type:', type, 'payload:', payload)
  })
  store.subscribeAction(({ type, payload }) => {
    Vue.$log.info('action', 'type:', type, 'payload:', payload)
  })
}
