
import { AppState } from './types'

export const state: AppState = {
  ready: false,
  interacted: false,
  tabs: [
    {id: 'favorites', text: 'navigation.favorites', icon: 'eco.heart-empty.white' },
    {id: 'rooms', text: 'navigation.rooms', icon: 'eco.quader-outline.white' },
    {id: 'energynext', text: 'navigation.energy', icon: 'eco.pv.white' },
    {id: 'scenes', text: 'navigation.scenes', icon: 'eco.image.white' },
    {id: 'more', text: 'navigation.more', icon: 'eco.more.white' },
  ],
  isLoading: true,
  selectedProjectId: '',
  selectedPlcId: '',
  selectedLanguage: '',
  includeDemoProjects: null,
  toast: null,
  versionInfo: null,
}
